export const careerData : string[] = [
    'General Aviation',
    'Airline Captain',
    'Airline First Officer',
    'Corporate Flying',
    'Chartar Flying',
    'Flight Instructor',
    'FAA Inspector', 
    'News/Traffic',
    'Firefighting',
    'Astronaut',
    'Power Patrol',
    'Glider Towing',
    'Parachute Jumping',
    'Agricultural',
    'Missionary',
    'Air Ambulance',
    'First Responder'
]