import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  dynamicLink: string = "/create-account";
  dynamicText: string = "Create an Account"
  profileUser: any = null;

  constructor(private auth: AuthService) { }

  ngOnInit(): void {
    this.auth.user$.subscribe((userData) => {
      if(userData) {
        console.log("user data", userData);
        this.profileUser = userData;
        this.dynamicLink = "/profile"
        this.dynamicText = "Customize your profile";
      }
    })
  }

}
