import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  openNavWindow: boolean = false;
  isMobile: boolean = false;

  constructor(public dialog: MatDialog, public auth: AuthService, public router: Router) { }

  ngOnInit(): void {
    this.isMobile = window.innerWidth <= 800 && window.innerHeight <= 900;
    console.log(window.innerWidth, window.innerHeight)
    console.log("mobile", this.isMobile)
  }

  openRegisterModal() {
   this.router.navigate(['/create-account'])
  }

  toggleNavWindow(){
    this.openNavWindow = !this.openNavWindow;
  }

  public signedIn() : boolean {
    return !!this.auth.currentUser;
  }

  public getUserImage(url: string) : string {
    if(!url) return 'https://firebasestorage.googleapis.com/v0/b/pilot-fast-track.appspot.com/o/default-user.jpeg?alt=media&token=8f9ef3cd-aed5-40d1-8737-6c4ad55066a4';
    return url;
  }

  logOut() {
    this.auth.signOut();
  }

}
