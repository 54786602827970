<!doctype html>
<html lang="en-us">

<head>
    <link href="https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css" rel="stylesheet">
</head>

<body style="background-color: #f9fafb; min-height: 100vh; height: 100%;">

    <app-navbar></app-navbar>

    <div class="">
        <div class="p-5 sm:p-20 sm:pt-30 pt-20" *ngIf="!complete">
            <div class="mt-6 md:mt-5 md:col-span-2">
                <mat-horizontal-stepper [linear]="true" #stepper>
                    <mat-step [stepControl]="firstFormGroup" [completed]="firstFormGroup.valid">
                        <form [formGroup]="firstFormGroup" class="mt-2">
                            <div class="mb-6">
                                <ng-template matStepLabel>General Information</ng-template>
                                <div class="col-span-6 sm:col-span-4">
                                    <label for="first_name" class="block text-sm font-medium text-gray-700">Scholarship
                                        name</label>
                                    <input type="text" placeholder="Scholarship Name" class="form-control"
                                        formControlName="scholarshipName"
                                        class="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                                    <div class="invalid"
                                        *ngIf="firstFormGroup.get('scholarshipName').touched && firstFormGroup.get('scholarshipName').invalid">
                                        Please enter a valid scholarship name
                                    </div>
                                </div>
                            </div>

                            <div class="mb-6">
                                <div class="col-span-6 sm:col-span-4">
                                    <label for="first_name" class="block text-sm font-medium text-gray-700">Issuer
                                        Name</label>
                                    <input type="text" placeholder="Issuer Name" class="form-control"
                                        formControlName="issuerName"
                                        class="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                                    <div class="invalid"
                                        *ngIf="firstFormGroup.get('issuerName').touched && firstFormGroup.get('issuerName').invalid">
                                        Please enter a valid issuer name
                                    </div>
                                </div>
                            </div>

                            <div class="mb-6">
                                <div class="col-span-6 sm:col-span-4">
                                    <label for="first_name" class="block text-sm font-medium text-gray-700">Issuer
                                        Website (Optional)</label>
                                    <input type="text" placeholder="Issuer Website" class="form-control"
                                        formControlName="issuerWebsite"
                                        class="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                                    <div class="invalid"
                                        *ngIf="firstFormGroup.get('issuerWebsite').touched && firstFormGroup.get('issuerWebsite').invalid">
                                        Please enter a valid issuer website
                                    </div>
                                </div>
                            </div>

                            <div class="mb-6">
                                <div class="col-span-6 sm:col-span-4" *ngIf="!isFlightHourCompensation()">
                                    <label class="block text-sm font-medium text-gray-700">Scholarship Amount</label>
                                    <input type="text" placeholder="Scholarship Amount" class="form-control"
                                        formControlName="money"
                                        class="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                                    <div class="invalid"
                                        *ngIf="firstFormGroup.get('money').touched && firstFormGroup.get('money').invalid">
                                        Please enter a valid amount
                                    </div>
                                </div>
                                <div class="col-span-6 sm:col-span-4" *ngIf="isFlightHourCompensation()">
                                    <label class="block text-sm font-medium text-gray-700">Flight Hours</label>
                                    <input type="text" placeholder="Flight Hours" class="form-control"
                                        formControlName="flightHours"
                                        class="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                                    <div class="invalid"
                                        *ngIf="firstFormGroup.get('flightHours').touched && firstFormGroup.get('flightHours').invalid">
                                        Please enter a valid amount 
                                    </div>
                                </div>
                                <div class="flex items-center mt-4">
                                    <input id="default-checkbox"  #flightHourCheckbox type="checkbox" formControlName="isFlightHourCompensation" (change)="onToggleFlightHours(flightHourCheckbox.checked)" [checked]="isFlightHourCompensation"  class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                    <label for="default-checkbox" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Flight hour award</label>
                                </div>
                            </div>


                            <div class="mb-6">
                                <div class="col-span-6 sm:col-span-4">
                                    <label class="block text-sm font-medium text-gray-700">Scholarship Deadline</label>
                                    <mat-form-field appearance="outline">
                                        <input matInput formControlName="scholarshipDeadline" [matDatepicker]="picker"
                                            [min]="minDate" [max]="maxDate">
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                    <div class="invalid"
                                        *ngIf="firstFormGroup.get('scholarshipDeadline').touched && firstFormGroup.get('scholarshipDeadline').invalid">
                                        Please enter a vaild scholarship deadline
                                    </div>
                                </div>
                            </div>

                            <div class="mb-6">
                                <div class="col-span-6 sm:col-span-4">
                                    <label class="block text-sm font-medium text-gray-700">Scholarship Website</label>
                                    <input type="text" placeholder="Scholarship Website" class="form-control"
                                        formControlName="scholarshipWebsite"
                                        class="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                                    <div class="invalid"
                                        *ngIf="firstFormGroup.get('scholarshipWebsite').touched && firstFormGroup.get('scholarshipWebsite').invalid">
                                        Please enter website link
                                    </div>
                                </div>
                            </div>

                            <div class="mb-6">
                                <div class="col-span-6 sm:col-span-4">
                                    <label class="block text-sm font-medium text-gray-700">Scholarship Details</label>
                                    <textarea type="text" placeholder="Scholarship Details" class="form-control"
                                        formControlName="scholarshipDetails"
                                        class="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"></textarea>
                                    <div class="invalid"
                                        *ngIf="firstFormGroup.get('scholarshipDetails').touched && firstFormGroup.get('scholarshipDetails').invalid">
                                        Please enter scholarship details (max length 300 characters)
                                    </div>
                                </div>
                            </div>
                            <div class="buttonContainer">
                                <button
                                    class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                    mat-button matStepperNext>Next</button>
                            </div>
                        </form>
                    </mat-step>


                    <mat-step [stepControl]="secondFormGroup" label="Scholarship Details">
                        <form [formGroup]="secondFormGroup" class="mt-2">
                            <div class="mb-6">
                                <div class="col-span-6 sm:col-span-3">
                                    <label class="block text-sm font-medium text-gray-700">Certification
                                        Requirement</label>
                                    <select autocomplete="goalCertification" formControlName="certificationRequirement"
                                        class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                                        <option value="None">No Certification Required</option>
                                        <option *ngFor="let cert of certifications" [value]="cert">{{cert}}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="mb-6">
                                <div class="col-span-6 sm:col-span-4">
                                    <label class="block text-sm font-medium text-gray-700">Age Range (Minimum)</label>
                                    <input type="number" placeholder="Age Range (Minimum)" class="form-control"
                                        formControlName="minAge"
                                        class="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                                    <div class="invalid"
                                        *ngIf="secondFormGroup.get('minAge').touched && secondFormGroup.get('minAge').invalid">
                                        Please enter a valid amount
                                    </div>
                                </div>
                            </div>

                            <div class="mb-6">
                                <div class="col-span-6 sm:col-span-4">
                                    <label class="block text-sm font-medium text-gray-700">Age Range (Maximum)</label>
                                    <input type="number" placeholder="Age Range (Maximum)" class="form-control"
                                        formControlName="maxAge"
                                        class="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                                    <div class="invalid"
                                        *ngIf="secondFormGroup.get('maxAge').touched && secondFormGroup.get('maxAge').invalid">
                                        Please enter a valid age
                                    </div>
                                </div>
                            </div>

                            <div class="mb-6">
                                <div class="col-span-6 sm:col-span-4">
                                    <label class="block text-sm font-medium text-gray-700">Location (Optional)</label>
                                    <input type="text" placeholder="Enter Location" class="form-control"
                                        formControlName="location"
                                        class="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                                    <div class="invalid"
                                        *ngIf="secondFormGroup.get('location').touched && secondFormGroup.get('location').invalid">
                                        Please enter a valid location
                                    </div>
                                </div>
                            </div>

                            <div class="mb-6">
                                <div class="col-span-6 sm:col-span-3">
                                    <label class="block text-sm font-medium text-gray-700">Aircraft</label>
                                    <select formControlName="aircraft"
                                        class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                                        <option value="Any">Any</option>
                                        <option *ngFor="let aircraft of aircrafts" [value]="aircraft">{{aircraft}}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="mb-6">
                                <div class="col-span-6 sm:col-span-3">
                                    <label class="block text-sm font-medium text-gray-700">Career Path</label>
                                    <select formControlName="career"
                                        class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                                        <option value="Any">Any</option>
                                        <option *ngFor="let career of careers" [value]="career">{{career}}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="mb-6">
                                <div class="col-span-6 sm:col-span-3">
                                    <label class="block text-sm font-medium text-gray-700">Rating</label>
                                    <select formControlName="rating"
                                        class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                                        <option value="Any">Any</option>
                                        <option *ngFor="let rating of ratings" [value]="rating">{{rating}}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="mb-6">
                                <div class="col-span-6 sm:col-span-3">
                                    <label class="block text-sm font-medium text-gray-700">Educational Level</label>
                                    <select formControlName="education"
                                        class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                                        <option value="Any">Any</option>
                                        <option value="High School">High School</option>
                                        <option value="Undergraduate">Undergraduate</option>
                                        <option value="Graduate">Graduate</option>
                                    </select>
                                </div>
                            </div>

                            <div class="mb-6">
                                <div class="col-span-6 sm:col-span-3">
                                    <label class="block text-sm font-medium text-gray-700">Gender Specific
                                        Scholarship</label>
                                    <select formControlName="gender"
                                        class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                                        <option value="Both">Not Gender Specific</option>
                                        <option value="Female">Yes. Female</option>
                                        <option value="Male">Yes. Male</option>
                                    </select>
                                </div>
                            </div>

                            <div class="mb-6">
                                <div class="col-span-6 sm:col-span-3">
                                    <label class="block text-sm font-medium text-gray-700">Additional Info</label>
                                    <select formControlName="specialFilter"
                                        class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                                        <option value="NA">Not Applicable</option>
                                        <option *ngFor="let filter of specialFilters" [value]="filter">{{filter}}</option>
                                    </select>
                                </div>
                            </div>

                            

                            <div class="buttonContainer">
                                <button
                                    class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                    mat-button matStepperPrevious>Back</button>
                                    <button
                                    class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mx-4"
                                    mat-button (click)="checkSubmit()">
    
                                    <span *ngIf="loading">
                                        <svg role="status" class="inline mr-3 w-4 h-4 text-white animate-spin"
                                            viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="#E5E7EB" />
                                            <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentColor" />
                                        </svg>
                                        Loading...
                                    </span>
    
    
                                    <span *ngIf="!loading">Create
                                        Scholarship</span>
                                </button>
                            </div>
                        </form>
                    </mat-step>

                    <!-- <mat-step [stepControl]="secondFormGroup" label="Upload Images">
                        <div style="margin-bottom: 25px;">


                            
                            <article aria-label="File Upload Modal"
                                class="relative h-full flex flex-colrounded-md"
                                dropzone (hovered)="toggleHover($event)" (dropped)="onDrop($event)"
                                [class.hovering]="isHovering">

                                
                                <section class="h-full overflow-auto p-8 w-full h-full flex flex-col">
                                    <header
                                        class="border-dashed border-2 border-gray-400 py-12 flex flex-col justify-center items-center">
                                        <p class="mb-3 font-semibold text-gray-900 flex flex-wrap justify-center">
                                            <span>Drag and drop your</span>&nbsp;<span>files anywhere or</span>
                                        </p>
                                        <input type="file" #file accept="image/jpeg,image/x-png" id="customFile" name="file"
                                        style="display: none" (change)="onDrop($event.target.files)">
                                        <button id="button" (click)="file.click()"
                                            class="mt-2 rounded-sm px-3 py-1 bg-gray-200 hover:bg-gray-300 focus:shadow-outline focus:outline-none">
                                            Upload a file
                                        </button>
                                    </header>

                                    <h1 class="pt-8 pb-3 font-semibold sm:text-lg text-gray-900">
                                        Preview Image
                                    </h1>

                                    <ul id="gallery" class="flex flex-1 flex-wrap -m-1">
                                        <li id="empty" *ngIf="files.length == 0 && !uploadLoading"
                                            class="h-full w-full text-center flex flex-col items-center justify-center items-center">
                                            <img class="mx-auto w-32"
                                                src="https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png"
                                                alt="no data" />
                                            <span class="text-small text-gray-500">No files selected</span>
                                        </li>
                                        <div *ngIf="uploadLoading">
                                            <img src="../../../../assets/images/Loading_Spinner.gif" class="spinner" />
                                        </div>
                                        <div *ngFor="let file of files">
                                            <app-upload-task [file]="file" (imageURL)="updateImageUrl($event)"></app-upload-task>
                                        </div>
                                    </ul>
                                </section>

                            </article>

                            <p class="invalid" *ngIf="imageError">{{imageError}}</p>
                        </div>

                        

                        <div class="buttonContainer">
                            <button
                                class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                mat-button matStepperPrevious>Back</button>
                            <button
                                class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mx-4"
                                mat-button (click)="checkSubmit()">

                                <span *ngIf="loading">
                                    <svg role="status" class="inline mr-3 w-4 h-4 text-white animate-spin"
                                        viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                            fill="#E5E7EB" />
                                        <path
                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                            fill="currentColor" />
                                    </svg>
                                    Loading...
                                </span>


                                <span *ngIf="!loading">Create
                                    Scholarship</span>
                            </button>
                        </div>
                    </mat-step> -->

                </mat-horizontal-stepper>
            </div>
        </div>

        <div *ngIf="complete" class="p-10 sm:p-20 sm:pt-30 pt-40">
            <div class="mt-6 md:mt-5 md:col-span-2 bg-white p-10" style="text-align: center;">
                <span class="justify-center mb-20 bg-blue-600" style="margin: auto;"><svg style="width: 60px !important; margin: auto;"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM337 209L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" fill="#1C4ED8"/></svg></span>
                <p class="font-medium text-gray-700 mt-5 ">Thank you for submitting a scholarship. It will appear in the scholarship page soon.</p>
                <div><i class="fa-solid fa-circle-check complete-icon"></i></div>
                <button (click)="restart()" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 mt-5" >Submit Another Scholarship</button>
            </div>
           
        </div>
    </div>

    <!-- <main class="border--bottom">
        <div class="container--lg pt1 pb1" *ngIf="!complete">
            <section class="pb4">

                <mat-horizontal-stepper [linear]="true" #stepper>
                    <mat-step [stepControl]="firstFormGroup" [completed]="firstFormGroup.valid">
                        <form [formGroup]="firstFormGroup" class="mt-2">
                            <div class="mb2">
                                <ng-template matStepLabel>General Information</ng-template>
                                <label for="name" class="form-label">Scholarship Name</label>
                                <input class="form-control" formControlName="scholarshipName" id="name"
                                    placeholder="Scholarship Name">
                                <div class="invalid"
                                    *ngIf="firstFormGroup.get('scholarshipName').touched && firstFormGroup.get('scholarshipName').invalid">
                                    Please enter a valid scholarship name
                                </div>
                            </div>

                            <div class="mb2">
                                <label for="issuer" class="form-label">Issuer Name</label>
                                <input class="form-control" formControlName="issuerName" id="issuer"
                                    placeholder="Issuer Name">
                                <div class="invalid"
                                    *ngIf="firstFormGroup.get('issuerName').touched && firstFormGroup.get('issuerName').invalid">
                                    Please enter a valid issuer name
                                </div>
                            </div>

                            <div class="mb2">
                                <label for="issuer" class="form-label">Issuer Website (Optional)</label>
                                <input class="form-control" formControlName="issuerWebsite" id="issuer"
                                    placeholder="Issuer Website">
                                <div class="invalid"
                                    *ngIf="firstFormGroup.get('issuerWebsite').touched && firstFormGroup.get('issuerWebsite').invalid">
                                    Please enter a valid issuer website
                                </div>
                            </div>

                            <div class="mb2">
                                <label class="form-label">Scholarship Amount</label>
                                <br />
                                <input type="text" class="form-control" type="number" formControlName="money" placeholder="Scholarship Amount">
                                <div class="invalid"
                                    *ngIf="firstFormGroup.get('money').touched && firstFormGroup.get('money').invalid">
                                    Please enter a valid amount
                                </div>
                            </div>


                            <div class="mb2">
                                <label for="date" class="form-label">Scholarship Deadline</label>
                                <br />
                                <mat-form-field appearance="outline">
                                    <mat-label>Choose a date</mat-label>
                                    <input matInput formControlName="scholarshipDeadline" [matDatepicker]="picker"
                                        [min]="minDate" [max]="maxDate">
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                                <div class="invalid"
                                    *ngIf="firstFormGroup.get('scholarshipDeadline').touched && firstFormGroup.get('scholarshipDeadline').invalid">
                                    Please enter a vaild scholarship deadline
                                </div>
                            </div>

                            <div class="mb2">
                                <label for="website" class="form-label">Scholarship Website</label>
                                <input class="form-control" formControlName="scholarshipWebsite" id="website" type="url"
                                    placeholder="Scholarship Website">
                                <div class="invalid"
                                    *ngIf="firstFormGroup.get('scholarshipWebsite').touched && firstFormGroup.get('scholarshipWebsite').invalid">
                                    Please enter website link
                                </div>
                            </div>

                            <div class="mb2">
                                <label for="details" class="form-label">Scholarship Details</label>
                                <textarea class="form-control" formControlName="scholarshipDetails" id="details"
                                    placeholder="Scholarship Details"></textarea>
                                <div class="invalid"
                                    *ngIf="firstFormGroup.get('scholarshipDetails').touched && firstFormGroup.get('scholarshipDetails').invalid">
                                    Please enter scholarship details (max length 300 characters)
                                </div>
                            </div>

                            <div class="buttonContainer">
                                <button class="button btn--default" mat-button matStepperNext>Next</button>
                            </div>
                        </form>
                    </mat-step>
                    <mat-step [stepControl]="secondFormGroup" label="Scholarship Details">
                        <form [formGroup]="secondFormGroup" class="mt-2">
                            <div class="mb2">
                                <label class="form-label">Certification Requirement</label>
                                <br />
                                <mat-form-field appearance="outline" class="select-list">
                                    <mat-select formControlName="certificationRequirement">
                                        <mat-option value="None">No Certification Required</mat-option>
                                        <mat-option *ngFor="let cert of certifications" [value]="cert">{{cert}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                            </div>

                            <div class="mb2">
                                <label class="form-label">Age Range (Minimum)</label>
                                <br />
                                <input type="text" class="form-control" type="number" formControlName="minAge">
                                <div class="invalid"
                                    *ngIf="secondFormGroup.get('minAge').touched && secondFormGroup.get('minAge').invalid">
                                    Please enter a valid age
                                </div>
                            </div>


                            <div class="mb2">
                                <label class="form-label">Age Range (Maximum)</label>
                                <br />
                                <input type="text" class="form-control" type="number" formControlName="maxAge">
                                <div class="invalid"
                                    *ngIf="secondFormGroup.get('maxAge').touched && secondFormGroup.get('maxAge').invalid">
                                    Please enter a valid age
                                </div>
                            </div>

                            <div class="mb2">
                                <label class="form-label">Gender Specific Scholarship</label>
                                <br />
                                <mat-form-field appearance="outline" class="select-list">
                                    <mat-select formControlName="gender">
                                        <mat-option value="Both">Not Gender Specific</mat-option>
                                        <mat-option value="Female">Yes. Female</mat-option>
                                        <mat-option value="Male">Yes. Male</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>


                            <div class="buttonContainer">
                                <button class="button btn--default backbutton" mat-button matStepperPrevious>Back</button>
                                <button class="button btn--default ms-4" mat-button matStepperNext>Next</button>
                            </div>
                        </form>
                    </mat-step>
                    <mat-step label="Upload Images">
                        <div style="margin-bottom: 25px;">
                        <div class="dropzone" dropzone (hovered)="toggleHover($event)" (dropped)="onDrop($event)"
                            [class.hovering]="isHovering">

                            <div *ngIf="!uploadLoading" class="fileUploadContainer">
                                <i class="fa-solid fa-cloud-arrow-up upload-icon"></i>
                                <h3>Upload Scholarship Image</h3>
                                <p>Drag and Drop a File</p>
                                <input type="file" #file accept="image/jpeg,image/x-png" id="customFile" name="file" style="display: none" (change)="onDrop($event.target.files)">
                                <button class="button btn--default mt1 mb1 selectfilebutton" (click)="file.click()">Select a File</button>
                            </div>

                            <div *ngIf="uploadLoading">
                                <img src="../../../../assets/images/Loading_Spinner.gif" class="spinner"/>
                            </div>
                            
                        </div>
                        <p class="invalid" *ngIf="imageError">{{imageError}}</p>
                    </div>

                            <p>Preview Image</p>
                        
                            <div *ngFor="let file of files">
                                <app-upload-task [file]="file" (imageURL)="updateImageUrl($event)"></app-upload-task>
                            </div>
                        
                        <div class="buttonContainer">
                            <button class="button btn--default backbutton" mat-button matStepperPrevious>Back</button>
                            <button class="button btn--default ms-4" mat-button (click)="checkSubmit()"><mat-spinner *ngIf="loading"></mat-spinner><span *ngIf="!loading">Create
                                Scholarship</span></button>
                        </div>
                    </mat-step>
                </mat-horizontal-stepper>

            </section>
        </div>

        <div *ngIf="complete" class="complete-view">
            <p>Thank you for submitting a scholarship. It will appear in the scholarship page soon.</p>
            <div><i class="fa-solid fa-circle-check complete-icon"></i></div>
            <button class="button btn--default submit-button" (click)="restart()">Submit Another Scholarship</button>
        </div>
    </main> -->

    <!-- Footer -->
    <app-footer></app-footer>

</body>

</html>