import { Inject, Component, forwardRef, Optional } from '@angular/core';

import {
  BaseWidget,
  NgAisInstantSearch,
  NgAisIndex,
} from 'angular-instantsearch';
import { connectHits } from 'instantsearch.js/es/connectors';

export type NoResultsState = {
  results?: {
    nbHits: number;
    getRefinements: () => any[];
  };
};

@Component({
  selector: 'app-no-results',
  templateUrl: './no-results.component.html',
  styleUrls: ['./no-results.component.scss']
})
export class NoResults extends BaseWidget {
  public state: NoResultsState = {};

  constructor(
    @Inject(forwardRef(() => NgAisIndex))
    @Optional()
    public parentIndex: NgAisIndex,
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchInstance: NgAisInstantSearch
  ) {
    super('NoResults');
  }

  ngOnInit() {
    this.createWidget(connectHits);
    super.ngOnInit();
  }
}