import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/shared/models/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent implements OnInit {
  fullName: string = "";
  email: string = "";
  password: string = "";
  confirmPassword: string = "";
  fullNameError: string = "";
  emailError: string = "";
  passwordError: string = "";
  confirmPasswordError: string = "";
  signupLoading: boolean = false;

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
  }

  public doesHaveError(value: string): boolean {
    if(value && value.length > 0) return true;
    return false;
  }

  async verifySignUp(){
    if(!this.signupLoading) {
      this.fullNameError = "";
      this.emailError = "";
      this.passwordError = "";
      let newUser = {
        uid: '',
        email: this.email,
        name: this.fullName,
        photoURL: ''
      } as User;
  
      if(this.fullName.length == 0 || this.fullName.length > 40) {
        this.fullNameError = "Name must be filled out and be between 4 and 40 characters";
        return;
      }
  
      if(this.email.length == 0 || this.email.length > 50) {
        this.emailError = "Please enter a valid email address";
        return;
      }
  
      if(this.password.length == 0 || this.password.length > 50) {
        this.passwordError = "Password must be between 6 and 50 characters";
        return;
      }

      if(this.confirmPassword != this.password) {
        this.confirmPasswordError = "Passwords must match";
        return;
      }
  
      // + init error catching then dont clear to def vals
      this.signupLoading = true;
      let result = await this.authService.signUp(newUser, this.password);
      if(result) {
        if(result.code == "auth/email-already-in-use") {
          this.emailError = "Email already in use. ";
        }else if(result.code == "auth/invalid-email") {
          this.emailError = "Please enter a valid email address";
        }else if(result.code == "auth/weak-password") {
          this.passwordError = "Weak password.";
        }
      }else {
        this.fullName = "";
        this.email = "";
        this.password = "";
      }
      this.signupLoading = false;
    }
  
  }

  async googleSignUp(){
    await this.authService.googleSignUp();
  }

}
