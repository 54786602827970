<!doctype html>
<html lang="en-us">

<head>
    <!-- Bootstrap -->
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.0/dist/css/bootstrap.min.css"
        integrity="sha384-gH2yIJqKdNHPEq0n4Mqa/HGKIhSkIHeL5AyhkYV8i59U5AR6csBvApHHNl/vI1Bx" crossorigin="anonymous">
</head>

<body>

    <app-navbar></app-navbar>


    <!-- Header -->
    <header class="align--center pt3">
        <div class="container--lg">
            <h1 class="mb1">Contact Us</h1>
        </div>
    </header>

    <main class="border--bottom">
        <div class="container--lg pt1 pb1">

            <section class="pb4">                    
                <form class="form">
                    <input class="form-control mb1" placeholder="Full Name">
                    <input class="form-control mb1" type="email" placeholder="Email Address">                        
                    <textarea class="form-control mb1" placeholder="Message" style="min-height: 200px;"></textarea>
                    <div class="button-container">
                        <button class="button mt1 btn--default">Send</button>
                    </div>
                    
                </form>
            </section>


        </div>
        
    </main>

    <!-- Footer -->
    <app-footer></app-footer>


</body>

</html>