import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './components/pages/about/about.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { CreateAccountComponent } from './components/pages/create-account/create-account.component';
import { EditProfileComponent } from './components/pages/edit-profile/edit-profile.component';
import { HomeComponent } from './components/pages/home/home.component';
import { LoginComponent } from './components/pages/login/login.component';
import { ProfileComponent } from './components/pages/profile/profile.component';
import { SavedScholarshipsComponent } from './components/pages/saved-scholarships/saved-scholarships.component';
import { ScholarshipViewComponent } from './components/pages/scholarship-view/scholarship-view.component';
import { ScholarshipsComponent } from './components/pages/scholarships/scholarships.component';
import { SubmitScholarshipsComponent } from './components/pages/submit-scholarships/submit-scholarships.component';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'about', component: AboutComponent},
  {path: 'scholarships', component: ScholarshipsComponent},
  {
    path: 'scholarship/:scholarshipID',
    component: ScholarshipViewComponent,
    pathMatch: 'full'
  },
  {path: 'contact', component: ContactComponent},
  {path: 'profile', component: ProfileComponent, canActivate: [AuthGuard]},
  {path: 'saved', component: SavedScholarshipsComponent, canActivate: [AuthGuard]},
  {path: 'profile/edit', component: EditProfileComponent, canActivate: [AuthGuard]},
  {path: 'submit', component: SubmitScholarshipsComponent},
  {path: 'create-account', component: CreateAccountComponent},
  {path: 'login', component: LoginComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
