<!doctype html>
<html lang="en-us">

<head>
    <link href="https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css" rel="stylesheet">
</head>

<body style="background-color: #f9fafb; min-height: 100vh; height: 100%;">

    <app-navbar></app-navbar>
        
        <div class="p-2 sm:p-20 pt-20">
            <div class="w-full px-3 mb-4">
                <div
                    class="relative flex flex-col h-full min-w-0 bg-white border-0 shadow-soft-xl rounded-2xl bg-clip-border">
                    <div class="p-4 pb-0 mb-0 bg-white border-b-0 rounded-t-2xl">
                        <div class="flex flex-wrap -mx-3">
                            <div class="flex justify-between w-full max-w-full px-3 shrink-0 md:flex-none">
                                <h6 class="mb-0">Saved Scholarships</h6>
                            </div>
                        </div>
                    </div>


                    <div *ngIf="savedScholarships.length == 0" class="flex-auto p-4">
                        <p>No scholarships saved yet.</p>
                    </div>

                    <div *ngIf="savedScholarships.length > 0" class="p-5">
                        <div class="flex-auto box-shadow" *ngFor="let scholarship of savedScholarships" style="cursor: pointer; margin-bottom: 1.5rem;" (click)="openHit(scholarship.id)">
                            <div class=" w-full lg:max-w-full lg:flex">
                                <!-- <div class="h-48 lg:h-auto lg:w-48 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden"
                                    [ngStyle]="{ 'background-image': 'url(' + scholarship.mainImage + ')'}"
                                    >
                                </div> -->
                                <div
                                    class="border rounded w-full border-gray-400 lg:border-t lg:border-gray-400 bg-white p-4 flex flex-col justify-between leading-normal">
                                    <div class="mb-4">
                                        <div class="text-gray-900 font-bold text-xl mb-2">{{scholarship.title}}
                                        </div>
                                        <p class="text-gray-700 text-base">{{truncateText(scholarship.description)}}</p> 
                                    </div>
                                    <div class="flex items-center">
                                        <div class="text-sm">
                                            <p class="text-gray-900 leading-none">Funded by {{scholarship.issuer}}</p>
                                            <p class="text-gray-600">{{getFormattedDate(scholarship.deadline)}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

</body>

</html>