<header class="z-40 fixed w-full">
  <nav class="bg-white border-gray-200 py-2.5 dark:bg-gray-900">
    <div class="flex flex-wrap items-center justify-between max-w-screen-xl px-4 mx-auto">
      <a href="#" class="flex items-center">
        <img src="../../../../assets/images/PFT_Icon.png" style="height: 3.5rem" alt="Pilot Fast Track Logo" />
        <!-- <span class="self-center text-xl font-semibold whitespace-nowrap dark:text-white">Pilot Fast Track</span> -->
      </a>
      <div class="flex items-center lg:order-2">
        <div *ngIf="signedIn()">

          <img id="avatarButton" type="button" data-dropdown-toggle="userDropdown" style="min-width: 40px;"
            data-dropdown-placement="bottom-start" class="w-10 h-10 rounded-full cursor-pointer"
            [src]="getUserImage(auth.currentUser.photoURL)" alt="User dropdown" (click)="toggleNavWindow()">

          <!-- Dropdown menu -->
          <div id="userDropdown" *ngIf="openNavWindow && !isMobile"
            class="z-40 w-44 bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700 dark:divide-gray-600"
            style="position: absolute; transform: translate3d(0px, 10px, 0px); left: 85%;"
            data-popper-reference-hidden="" data-popper-escaped="" data-popper-placement="bottom-end">
            <ul class="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="avatarButton" *ngIf="signedIn()">
              <li>
                <a href="/profile"
                  class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Profile</a>
              </li>
              <li>
                <a href="/saved"
                  class="block py-2 px-4 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Saved
                  Scholarships</a>
              </li>
            </ul>
            <div class="py-1">
              <a
                class="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white" (click)="logOut()">Sign
                out</a>
            </div>
          </div>


        </div>
        <!-- <a href="#" class="text-gray-800 dark:text-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800">Log in</a> -->
        <a 
          class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 lg:mr-0 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          (click)="openRegisterModal()" *ngIf="!signedIn()" style="cursor: pointer;">Create Account</a>
        <button (click)="toggleNavWindow()" data-collapse-toggle="mobile-menu-2" type="button"
          class="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-controls="mobile-menu-2" aria-expanded="false">
          <span class="sr-only">Open main menu</span>
          <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"  *ngIf="!openNavWindow">
            <path fill-rule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clip-rule="evenodd"></path>
          </svg>
          <svg class=" w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"  *ngIf="openNavWindow">
            <path fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"></path>
          </svg>
        </button>
      </div>
      <div class="items-center justify-between  w-full lg:flex lg:w-auto lg:order-1" id="mobile-menu-2"  *ngIf="openNavWindow || !isMobile">
        <ul class="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
          <li>
            <a href="/" (click)="toggleNavWindow()"
              class="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
              aria-current="page">Home</a>
          </li>
          <li>
            <a (click)="toggleNavWindow()" routerLink="/scholarships" [ngClass]="{'active':router.url === '/scholarships'}"
              class="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Scholarships</a>
          </li>
          <li>
            <a (click)="toggleNavWindow()" routerLink="/about" [ngClass]="{'active':router.url === '/about'}"
              class="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">About</a>
          </li>
          <li>
            <a (click)="toggleNavWindow()" href="mailto:contact@pilotfasttrack.com" [ngClass]="{'active':router.url === '/contact'}"
              class="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Contact</a>
          </li>
          <li>
            <a (click)="toggleNavWindow()" routerLink="/submit" [ngClass]="{'active':router.url === '/submit'}"
              class="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Submit
              Scholarship</a>
          </li>
          <div *ngIf="isMobile && signedIn()">
            <ul class="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="avatarButton" *ngIf="signedIn()">
              <li>
                <a href="/profile"
                class="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Profile</a>
              </li>
              <li>
                <a href="/saved"
                class="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Saved
                  Scholarships</a>
              </li>
              <li>
                <a
              class="block py-2 pl-3 pr-4 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700" (click)="logOut()">Sign
                out</a>
              </li>
            </ul>
            
          </div>
        </ul>
      </div>
    </div>
  </nav>
</header>