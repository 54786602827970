export const ratingsData : string[] = [
    'Multi-Engine',
    'Instrument',
    'Ground Instructor',
    'CFI',
    'CFII',
    'MEI',
    'High-Performance',
    'Complex',
    'Tailwheel',
    'Helicopters',
    'Gyroplanes', 
    'Gliders',
    'Balloons',
    'Airships',
    'Seaplanes'
]