import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import algoliasearch from 'algoliasearch/lite'
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FilterModalComponent } from '../../parts/filter-modal/filter-modal.component';
import firebase from 'firebase/app';


const searchClient = algoliasearch(
  environment.algolia.appId,
  environment.algolia.apiKey,
);

@Component({
  selector: 'app-scholarships',
  templateUrl: './scholarships.component.html',
  styleUrls: ['./scholarships.component.scss']
})
export class ScholarshipsComponent implements OnInit {
  

  constructor(private router: Router, private dialog: MatDialog) { }

  searchConfig = {
    indexName: 'scholarships',
    searchClient
  }

  ngOnInit(): void {
  
  }

  truncateText(text: string): string {
    if(text.length > 400) {
      return text.substring(0, 400).concat('...');
    }
    return text;
  }

  getFormattedDate(input): string {
    let date = new firebase.firestore.Timestamp(input['_seconds'], input['_nanoseconds']).toDate();
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
  
    return month + '/' + day + '/' + year;
}

  openFilter() {
    this.dialog.open(FilterModalComponent, {
      width: '500px',
      hasBackdrop: false,
      backdropClass: 'custom-class',
      panelClass: 'custom-class'
    });
  }
  
  openHit(hitId: string) {
    this.router.navigate([`/scholarship/${hitId}`]);
  }

  visitWebsite(url: string){
    window.open(url, "_blank");
  }


}
