<!doctype html>
<html lang="en-us">

<head>
    <link href="https://unpkg.com/tailwindcss@^1.0/dist/tailwind.min.css" rel="stylesheet">
</head>

<body style="background-color: #f9fafb; min-height: 100vh; height: 100%;">

    <app-navbar></app-navbar>

    <div class="ease-soft-in-out rounded-xl transition-all duration-200" id="panel">

        <div class="z-10 w-full px-6 py-20 mx-auto text-slate-500" style="padding-top: 6rem;"
            *ngIf="profileUser && !loading">
            <div
                class="z-10 relative flex flex-col flex-auto min-w-0 p-4 overflow-hidden break-words border-0 shadow-blur rounded-2xl bg-white/80 bg-clip-border mb-4">
                <div class="flex flex-wrap -mx-3">
                    <div class="flex-none w-auto max-w-full px-3">
                        <div
                            class="text-size-base ease-soft-in-out h-18.5 w-18.5 relative inline-flex items-center justify-center rounded-xl text-white transition-all duration-200">
                            <img [src]="profileUser.photoURL" alt="profile_image"
                                class="w-full shadow-soft-sm rounded-xl">
                        </div>
                    </div>
                    <div class="flex-none w-auto max-w-full px-3 my-auto">
                        <div class="h-full">
                            <h5 class="mb-1">{{profileUser.name}}</h5>
                            <p class="mb-0 font-semibold leading-normal text-size-sm">
                                {{getArrayValues(profileUser.currentCertification)}}</p>
                            <p class="mb-0 font-semibold leading-normal text-size-sm">
                                {{getAircraft(profileUser.aircraft)}}</p>
                        </div>
                    </div>

                </div>
            </div>
            <div class="w-full mt-6 mx-auto removable">
                <div class="flex flex-wrap -mx-3">

                    <div class="w-full max-w-full px-3 lg-max:mt-6 xl:w-4/12 mb-4">
                        <div
                            class="relative flex flex-col h-full min-w-0 break-words bg-white border-0 shadow-soft-xl rounded-2xl bg-clip-border">
                            <div class="p-4 pb-0 mb-0 bg-white border-b-0 rounded-t-2xl">
                                <div class="flex flex-wrap -mx-3">
                                    <div
                                        class="flex items-center w-full max-w-full px-3 shrink-0 md:w-8/12 md:flex-none">
                                        <h6 class="mb-0">Profile Information</h6>
                                    </div>
                                    <div class="w-full max-w-full px-3 text-right shrink-0 md:w-4/12 md:flex-none">
                                        <a href="javascript:;" data-target="tooltip_trigger" data-placement="top">
                                            <i class="leading-normal fas fa-user-edit text-size-sm text-slate-400"
                                                aria-hidden="true"></i>
                                        </a>
                                        <div data-target="tooltip"
                                            class="px-2 py-1 text-center text-white bg-black rounded-lg text-size-sm hidden"
                                            role="tooltip" data-popper-placement="top"
                                            style="position: absolute; inset: auto auto 0px 0px; margin: 0px; transform: translate3d(869.5px, -417.5px, 0px);">
                                            Edit Profile
                                            <div class="invisible absolute h-2 w-2 bg-inherit before:visible before:absolute before:h-2 before:w-2 before:rotate-45 before:bg-inherit before:content-['']"
                                                data-popper-arrow=""
                                                style="position: absolute; left: 0px; transform: translate3d(0px, 0px, 0px);">
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="flex-auto p-4">
                                <ul class="flex flex-col pl-0 mb-0 rounded-lg">
                                    <li
                                        class="relative block px-4 py-2 pt-0 pl-0 leading-normal bg-white border-0 rounded-t-lg text-size-sm text-inherit">
                                        <strong class="text-slate-700">Full Name:</strong> &nbsp;
                                        {{getDefaultFormValues(profileUser.name)}}</li>
                                    <li
                                        class="relative block px-4 py-2 pl-0 leading-normal bg-white border-0 border-t-0 text-size-sm text-inherit">
                                        <strong class="text-slate-700">Email:</strong> &nbsp;
                                        {{getDefaultFormValues(profileUser.email)}}</li>
                                    <li
                                        class="relative block px-4 py-2 pl-0 leading-normal bg-white border-0 border-t-0 text-size-sm text-inherit">
                                        <strong class="text-slate-700">Certification:</strong> &nbsp;
                                        {{getArrayValues(profileUser.currentCertification, 'Certifications')}}</li>
                                    <li
                                        class="relative block px-4 py-2 pl-0 leading-normal bg-white border-0 border-t-0 text-size-sm text-inherit">
                                        <strong class="text-slate-700">Gender:</strong> &nbsp;
                                        {{getDefaultFormValues(profileUser.gender)}}</li>
                                    <li
                                        class="relative block px-4 py-2 pl-0 leading-normal bg-white border-0 border-t-0 text-size-sm text-inherit">
                                        <strong class="text-slate-700">Age:</strong> &nbsp;
                                        {{getDefaultFormValues(profileUser.age)}}</li>
                                    <li
                                        class="relative block px-4 py-2 pl-0 leading-normal bg-white border-0 border-t-0 text-size-sm text-inherit">
                                        <strong class="text-slate-700">Aircraft:</strong> &nbsp;
                                        {{getDefaultFormValues(profileUser.aircraft)}}</li>
                                    <li
                                        class="relative block px-4 py-2 pl-0 leading-normal bg-white border-0 border-t-0 text-size-sm text-inherit">
                                        <strong class="text-slate-700">Career Track:</strong> &nbsp;
                                        {{getDefaultFormValues(profileUser.career)}}</li>
                                    <li
                                        class="relative block px-4 py-2 pl-0 leading-normal bg-white border-0 border-t-0 text-size-sm text-inherit">
                                        <strong class="text-slate-700">Goal Certification:</strong> &nbsp;
                                        {{getDefaultFormValues(profileUser.goalCertification)}}</li>

                                    <li
                                        class="relative block px-4 py-2 pl-0 leading-normal bg-white border-0 border-t-0 text-size-sm text-inherit">
                                        <strong class="text-slate-700">Goal Rating:</strong> &nbsp;
                                        {{getDefaultFormValues(profileUser.goalRating)}}</li>

                                    <li
                                        class="relative block px-4 py-2 pl-0 leading-normal bg-white border-0 border-t-0 text-size-sm text-inherit">
                                        <strong class="text-slate-700">Additional Filter:</strong> &nbsp;
                                        {{getArrayValues(profileUser.specialFilter, 'Additional Filters')}}</li>
                                    
                                    
                                        <button type="button" (click)="navigate('/profile/edit')"
                                        class="mt-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Edit
                                        Profile</button>

                                    <button type="button" (click)="signOut()"
                                        class="mt-4 text-white bg-blue-800 hover:bg-blue-900 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">Log Out</button>

                                </ul>
                            </div>

                        </div>
                    </div>

                    <div class="w-full px-3 lg-max:mt-6 sm:w-8/12 mb-4">
                        <div
                            class="relative flex flex-col h-full min-w-0 bg-white border-0 shadow-soft-xl rounded-2xl bg-clip-border">
                            <div class="p-4 pb-0 mb-0 bg-white border-b-0 rounded-t-2xl">
                                <div class="flex flex-wrap -mx-3">
                                    <div class="flex justify-between w-full max-w-full px-3 shrink-0 md:flex-none">
                                        <h6 class="mb-0">Saved Scholarships</h6>
                                    </div>
                                </div>
                            </div>


                            <div *ngIf="savedScholarships.length == 0" class="flex-auto p-4">
                                <p>No scholarships saved yet.</p>
                            </div>

                            <div *ngIf="savedScholarships.length > 0" class="p-5">
                                <div class="flex-auto box-shadow" *ngFor="let scholarship of savedScholarships" style="cursor: pointer; margin-bottom: 2rem;" (click)="openHit(scholarship.id)">
                                    <div class=" w-full lg:max-w-full lg:flex">
                                        <!-- <div class="h-48 lg:h-auto lg:w-48 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden"
                                            [ngStyle]="{ 'background-image': 'url(' + scholarship.mainImage + ')'}"
                                            >
                                        </div> -->
                                        <div
                                            class="border w-full border-gray-400 lg:border-t lg:border-gray-400 bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 flex flex-col justify-between leading-normal">
                                            <div class="mb-4">
                                                <div class="text-gray-900 font-bold text-xl mb-2">{{scholarship.title}}
                                                </div>
                                                <p class="text-gray-700 text-base">{{truncateText(scholarship.description)}}</p> 
                                            </div>
                                            <div class="flex items-center">
                                                <div class="text-sm">
                                                    <p class="text-gray-900 leading-none">Funded by {{scholarship.issuer}}</p>
                                                    <p class="text-gray-600">{{getFormattedDate(scholarship.deadline)}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="pl-4 pb-5" *ngIf="savedScholarships.length > 0">
                                <button type="button"
                                    (click)="router.navigate(['/saved'])"
                                    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    View all Saved
                                    <svg aria-hidden="true" class="ml-2 -mr-1 w-5 h-5" fill="currentColor"
                                        viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd"
                                            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                            clip-rule="evenodd"></path>
                                    </svg>
                                </button>
                            </div>

                        </div>
                    </div>


                </div>

            </div>
        </div>
    </div>

</body>

</html>