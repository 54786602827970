import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, DocumentChangeAction } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { User } from '../models/user.model';
import { map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
// might need to change (import app from 'firebase/app')
import firebase from 'firebase/app';
import 'firebase/auth';
import { Scholarship } from '../models/scholarship.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public currentUser: User;
  user$: Observable<any>;
  userDeals$: any | undefined;
  location$: Observable<any>;
  loggedIn : boolean;
  location: any;
  
  constructor(private router: Router, private afAuth: AngularFireAuth, private afs: AngularFirestore) {  
    
    this.afAuth.authState.subscribe((user) => {
      if(user){
        console.log("logged", user)
        this.loggedIn = true;
        this.currentUser = {name: user.displayName, email: user.email, photoURL: null, uid: user.uid};

        this.afs.collection(`users`).doc(user.uid).get().subscribe((data: any) => {
          if(data) {
            console.log("photo url", data.data().photoURL);
            this.currentUser.photoURL = data.data().photoURL;
            this.currentUser.savedScholarships = data.data().savedScholarships;
          }
        })

        // let userDeals = this.afs.collection('deals', ref => {
        //   return ref.where('userProfile.uid', '==', this.currentUser.uid).orderBy("createdAt");
        // });
        // this.userDeals$ = userDeals.snapshotChanges().pipe(map(deals => {
        //   return deals.map((deal) => {
        //     let retrievedDoc = deal.payload.doc.data() as Scholarship;
        //     retrievedDoc.id = deal.payload.doc.id;
        //     return retrievedDoc;
        //   })
        // }));
    
        return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
      }else{
        this.loggedIn = false;
        this.currentUser = null;
        this.userDeals$ = null;
        return of(null);
      }
    });

    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
        if(user){
          this.loggedIn = true;
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        }else{
          this.loggedIn = false;
          this.currentUser = null;
          this.userDeals$ = null;
          return of(null);
        }
      })
    );

  }

  async login(email, password): Promise<any>{
    let promise = this.afAuth.signInWithEmailAndPassword(email, password).then((res) => {
      this.loggedIn = true;
      this.router.navigate(['/profile']);
      return;
    })
    .catch((err) => {
      console.log(err)
      return err;
    })

    return promise;
  }

  async googleSignin(){
    const provider = new firebase.auth.GoogleAuthProvider();
    const creds = await this.afAuth.signInWithPopup(provider);
    const userRef = this.afs.firestore.doc(`users/${creds.user.uid}`);

    userRef.get().then((doc) => {
      if(!doc.exists){
        alert("User not created. Please sign up.");
        this.router.navigate(['/create-account']);
        return;
      }else{
        this.loggedIn = true;
        this.router.navigate(['/profile']);
        return;
      }
    })
  }

  async googleSignUp(){
    const provider = new firebase.auth.GoogleAuthProvider();
    const creds = await this.afAuth.signInWithPopup(provider);
    const userRef = this.afs.firestore.doc(`users/${creds.user.uid}`);

    userRef.get().then((doc) => {
      if(!doc.exists){
        let userData: User = {
          uid: creds.user.uid,
          email: creds.user.email,
          name: creds.user.displayName,
          photoURL: creds.user.photoURL,
          savedScholarships: []
        }

        this.afs.firestore.collection("users").doc(userData.uid).set(userData)
        .then(() => {
          this.loggedIn = true;
          this.router.navigate(['/profile/edit']);
          return;
        })
        .catch((err) => {
          alert("Error: " + err);
          return err;
        })

      }else{
        alert("User already created. Please sign in.");
        this.router.navigate(['/login']);
        return;
      }
    })
  }

  async checkUserExsists(newUser: User): Promise<any>{
    let userExists = await this.afAuth.fetchSignInMethodsForEmail(newUser.email);
    return userExists;
  }


  // TODO: enter photoURL link
  async signUp(newUser: User, userPassword: string): Promise<any>{
      let promise = this.afAuth.createUserWithEmailAndPassword(newUser.email, userPassword).then((uData) => {
          let userData: User = {
            uid: uData.user.uid,
            email: uData.user.email,
            name: newUser.name,
            photoURL: 'https://firebasestorage.googleapis.com/v0/b/pilot-fast-track.appspot.com/o/default-user.jpeg?alt=media&token=8f9ef3cd-aed5-40d1-8737-6c4ad55066a4',
            savedScholarships: []
          }
  
          this.afs.firestore.collection("users").doc(userData.uid).set(userData)
          .then(() => {
            this.loggedIn = true;
            this.router.navigate(['/profile/edit']);
            return;
          })
          .catch((err) => {
            alert("Error: " + err);
            return err;
          })
      }).catch((err) => {
        return err;
      })
      return promise; 
  }

  async signOut(){
    await this.afAuth.signOut();
    this.router.navigate(['/']);
  }

  async updateUserData(user: User): Promise<any>{
    let promise = this.afs.firestore.collection("users").doc(user.uid).update(user).then(() => {
      return;
    }).catch((err) => {
      return err;
    })

    return promise;
  }

  async deleteUser(user: User){
   this.afs.firestore.collection("users").doc(user.uid).delete().then(async() => {

    this.router.navigate(['/']);

    }).catch((err) => {
      console.log("err", err);
    })
  }

// Pagination Methods
paginate(limit: number, last: string): Observable<DocumentChangeAction<any>[]> {

  return this.afs.collection('users', (ref) => (
    ref
      .where('uid', '<', last)
      .orderBy('uid', 'desc')
      .orderBy('hash')
      .limit(limit)
  )).snapshotChanges();
}
}