<!doctype html>
<html lang="en-us">

<head>
  <link href="https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css" rel="stylesheet">
</head>

<body style="background-color: #f9fafb;">

  <app-navbar></app-navbar>
  <ais-instantsearch [config]="searchConfig">
    <div class="p-5 sm:p-20 pt-20">
      <div class="mt-3 md:mt-3 md:col-span-2 pt-3 search-scho">
        <ais-search-box placeholder="Search for scholarships with keyword"></ais-search-box>
        <button type="button"
        class="mt-4 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" [matMenuTriggerFor]="menu">Filter Search</button>
        <mat-menu #menu="matMenu">
          <div class="p-5" (click)="$event.stopPropagation();">
          <h3 class="text-lg font-bold text-gray-500 dark:text-gray-400">Filters</h3>
          <p class="mt-3 text-md font-bold text-blue-500">Amount</p>
          <!-- <app-refinement-list refinementAttribute="amount"></app-refinement-list> -->
          <ais-range-slider
              attribute="money"
              [pips]="false"
              [tooltips]="true"
              [precision]="0"
            >
            </ais-range-slider>

          <p class="mt-3 text-md font-bold text-blue-500">Aircraft</p>
          <app-refinement-list refinementAttribute="aircraft"></app-refinement-list>

          <p class="mt-3 text-md font-bold text-blue-500">Education Level</p>
          <app-refinement-list refinementAttribute="education"></app-refinement-list>
          <p class="mt-3 text-md font-bold text-blue-500">Career</p>
          <!-- <ais-refinement-list attribute="career" operator="and"></ais-refinement-list> -->
          <app-refinement-list refinementAttribute="career"></app-refinement-list>
          <p class="mt-3 text-md font-bold text-blue-500">Gender</p>
          <app-refinement-list refinementAttribute="gender"></app-refinement-list>
          <div class="mt-3">
          <ais-clear-refinements
            class="clear-filters"
            data-layout="desktop"
            resetLabel="Clear filters"
          ></ais-clear-refinements>
        </div>
        </div>
        </mat-menu>
        <div class="main-container mb-2">
          <ais-panel>
            <!-- <ais-refinement-list attribute="money" operator="and">
            </ais-refinement-list> -->
            <!-- <ais-refinement-list attribute="career" operator="and"></ais-refinement-list> -->
            
          </ais-panel>

          <ais-hits>
            <ng-template let-hits="hits">
              <ol class="ais-Hits-list">

                <div class="container mx-auto mt-10" *ngFor="let hit of hits" (click)="openHit(hit.objectID)">

                  <div class="box-shadow" style="background-color: white;">
                    <div class="space-y-4 lg:grid lg:grid-cols-4 lg:items-start lg:gap-6 lg:space-y-0 sm:pl-10 sm:pr-10 pl-5 pr-5 pt-5">


                      <div class="sm:col-span-3">


                        <div class="">

                          <a class="group">

                            <h3
                              class="text-lg leading-6 font-semibold font-sans text-skin-inverted group-hover:text-skin-primary text-black pt-1"
                              style="font-size: 20px;">{{hit.title}}</h3>

                          </a>

                          <p class="mt-3 text-md font-normal text-skin-base leading-5">Funded by <span
                              class="text-black"
                              *ngIf="!hit.issuerWebsite || hit.issuerWebsite.length == 0">{{hit.issuer}}</span> <a
                              class="issuerLink" (click)="visitWebsite(hit.issuerWebsite)"
                              *ngIf="hit.issuerWebsite && hit.issuerWebsite.length > 0"><span>{{hit.issuer}}</span></a>
                          </p>

                          <!-- TODO: make func to truncate description -->
                          <p class="mt-4 text-md font-normal text-skin-base leading-5">
                            {{truncateText(hit.description)}}
                          </p>

                        </div>

                      </div>

                    </div>

                    <div class="pt-5 lg:grid lg:grid-cols-4 lg:items-start lg:gap-6 pb-5 pl-2 sm:pl-10">
                      <div class="ml-3 mb-3 sm:mb-0">
                        <p class="mb-1">Amount</p>
                        <li class="flex space-x-3">
                          <!-- Icon -->
                          <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                            viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                            <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                            <path
                              d="M160 0c17.7 0 32 14.3 32 32V67.7c1.6 .2 3.1 .4 4.7 .7c10.6 1.6 42.1 6.7 55.1 10c17.1 4.3 27.5 21.7 23.2 38.9s-21.7 27.5-38.9 23.2c-9.3-2.4-37.6-7-48.9-8.7c-32.1-4.8-59.6-2.4-78.5 4.9c-18.3 7-25.9 16.9-27.9 28c-1.9 10.7-.5 16.8 1.3 20.6c1.9 4 5.6 8.5 12.9 13.4c16.2 10.8 41.1 17.9 73.3 26.7l2.8 .8c28.4 7.7 63.2 17.2 89 34.3c14.1 9.4 27.3 22.1 35.5 39.7c8.3 17.8 10.1 37.8 6.3 59.1c-6.9 38-33.1 63.4-65.6 76.7c-13.7 5.6-28.6 9.2-44.4 11V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V445.1c-.4-.1-.9-.1-1.3-.2l-.2 0 0 0c-24.4-3.8-64.5-14.3-91.5-26.3c-16.2-7.2-23.4-26.1-16.2-42.2s26.1-23.4 42.2-16.2c20.9 9.3 55.3 18.4 75.2 21.6c31.9 4.7 58.2 2 76-5.3c16.9-6.9 24.6-16.9 26.8-28.9c1.9-10.7 .5-16.8-1.3-20.6c-1.9-4-5.6-8.5-12.9-13.4c-16.2-10.8-41.1-17.9-73.3-26.7l-2.8-.8c-28.4-7.7-63.2-17.2-89-34.3c-14.1-9.4-27.3-22.1-35.5-39.7c-8.3-17.8-10.1-37.8-6.3-59.1C25 114.1 53 89.3 86 76.7c13-5 27.2-8.2 42-10V32c0-17.7 14.3-32 32-32z" />
                          </svg>
                          <span class="text-base font-medium leading-tight text-gray-900 dark:text-white" *ngIf="hit.money">${{hit.money}}</span>
                          <span class="text-base font-medium leading-tight text-gray-900 dark:text-white" *ngIf="hit.flightHours">{{hit.flightHours}} hours</span>
                        </li>
                      </div>

                      <div class="ml-3 mb-3 sm:mb-0">
                        <p class="mb-1">Education Level</p>
                        <li class="flex space-x-3">
                          <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                          viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg">
                            <path d="M320 32c-8.1 0-16.1 1.4-23.7 4.1L15.8 137.4C6.3 140.9 0 149.9 0 160s6.3 19.1 15.8 22.6l57.9 20.9C57.3 229.3 48 259.8 48 291.9v28.1c0 28.4-10.8 57.7-22.3 80.8c-6.5 13-13.9 25.8-22.5 37.6C0 442.7-.9 448.3 .9 453.4s6 8.9 11.2 10.2l64 16c4.2 1.1 8.7 .3 12.4-2s6.3-6.1 7.1-10.4c8.6-42.8 4.3-81.2-2.1-108.7C90.3 344.3 86 329.8 80 316.5V291.9c0-30.2 10.2-58.7 27.9-81.5c12.9-15.5 29.6-28 49.2-35.7l157-61.7c8.2-3.2 17.5 .8 20.7 9s-.8 17.5-9 20.7l-157 61.7c-12.4 4.9-23.3 12.4-32.2 21.6l159.6 57.6c7.6 2.7 15.6 4.1 23.7 4.1s16.1-1.4 23.7-4.1L624.2 182.6c9.5-3.4 15.8-12.5 15.8-22.6s-6.3-19.1-15.8-22.6L343.7 36.1C336.1 33.4 328.1 32 320 32zM128 408c0 35.3 86 72 192 72s192-36.7 192-72L496.7 262.6 354.5 314c-11.1 4-22.8 6-34.5 6s-23.5-2-34.5-6L143.3 262.6 128 408z"/>
                          </svg>
                          <span class="text-base font-medium leading-tight text-gray-900 dark:text-white">{{hit.education}}</span>
                        </li>
                      </div>

                      <div class="ml-3 mb-3 sm:mb-0">
                        <p class="mb-1">Required Certification</p>
                        <li class="flex space-x-3">
                          <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                            viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"
                            viewBox="0 0 576 512">
                            <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                            <path
                              d="M0 96l576 0c0-35.3-28.7-64-64-64H64C28.7 32 0 60.7 0 96zm0 32V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V128H0zM64 405.3c0-29.5 23.9-53.3 53.3-53.3H234.7c29.5 0 53.3 23.9 53.3 53.3c0 5.9-4.8 10.7-10.7 10.7H74.7c-5.9 0-10.7-4.8-10.7-10.7zM176 320c-35.3 0-64-28.7-64-64s28.7-64 64-64s64 28.7 64 64s-28.7 64-64 64zM352 208c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16zm0 64c0-8.8 7.2-16 16-16H496c8.8 0 16 7.2 16 16s-7.2 16-16 16H368c-8.8 0-16-7.2-16-16z" />
                          </svg>
                          <span
                            class="text-base font-medium leading-tight text-gray-900 dark:text-white">{{hit.requiredCertification}}</span>
                        </li>
                      </div>

                      <div class="ml-3 mb-3 sm:mb-0">
                        <p class="mb-1">Deadline</p>
                        <li class="flex space-x-3">
                          <svg class="flex-shrink-0 w-5 h-5 text-blue-500 dark:text-blue-400" fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                            <path
                              d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm80 64c-8.8 0-16 7.2-16 16v96c0 8.8 7.2 16 16 16h96c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80z" />
                          </svg>
                          <span
                            class="text-base font-medium leading-tight text-gray-900 dark:text-white">{{getFormattedDate(hit.deadline)}}</span>
                        </li>
                      </div>
                    </div>

                  </div>
                </div>


                <div style="margin: auto;">
                  <app-no-results></app-no-results>
                </div>

              </ol>
            </ng-template>
          </ais-hits>
          <div class="mt-10">
            <ais-pagination></ais-pagination>
          </div>


        </div>
      </div>
    </div>
  </ais-instantsearch>

  <!-- Footer -->
  <app-footer></app-footer>

</body>

</html>