import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import firebase from 'firebase/app';
import { User } from 'src/app/shared/models/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  constructor(public auth: AuthService, public router: Router, private afs: AngularFirestore) { }

  public profileUser: User;
  public loading: boolean = false;
  public savedScholarships: any[] = [];

  ngOnInit(): void {
    this.loading = true;
    this.auth.user$.subscribe((userData) => {
      if(userData) {
        console.log("user data", userData);
        this.profileUser = userData;
        if(this.profileUser.currentCertification == null) this.profileUser.currentCertification = [];
        this.getSaved();
      }
    })
    console.log(this.profileUser, "hi")
    this.loading = false;
  }

  truncateText(text: string): string {
    if(text.length > 200) {
      return text.substring(0, 200).concat('...');
    }
    return text;
  }

  getFormattedDate(input): string {
    let date = new firebase.firestore.Timestamp(input['seconds'], input['nanoseconds']).toDate();
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
  
    return month + '/' + day + '/' + year;
}

  openHit(hitId: string) {
    this.router.navigate([`/scholarship/${hitId}`]);
  }

  getAircraft(aircraft: string): string {
    if(aircraft && aircraft.length > 0) return aircraft;
    return 'Aircraft not Selected';
  }

  getDefaultFormValues(value: string): string {
    if(value && value.length > 0) return value;
    return 'Not Specified';
  } 

  getArrayValues(value: string[], specificationName: string) : string {
    if(value && value.length > 0) {
      return value.join(', ');
    }
    return `No ${specificationName} Selected`;
  }

  navigate(url: string) {
    this.router.navigate([url]);
  }

  // get first 3 saved scholarships
  private async getSaved() {
    this.savedScholarships = [];
    let scholarshipIDs = [];
    if(this.profileUser.savedScholarships.length > 3) {
      scholarshipIDs = this.profileUser.savedScholarships.slice(0, 3);
    }else{
      scholarshipIDs = this.profileUser.savedScholarships;
    }
    scholarshipIDs.forEach(async(id) => {
      this.afs.collection('scholarships').doc(id).get().toPromise().then((data: any) => {
        console.log("returnubg for", id, data.data());
        if(data.data()) {
          this.savedScholarships.push({id: data.id, ...data.data()});
        }
      })
    })
  }

  async signOut(){
    await this.auth.signOut();
    this.router.navigate(['/']);
  }

}
