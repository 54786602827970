import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  email: string = "";
  password: string = "";
  emailError: string = "";
  passwordError: string = "";
  loginLoading: boolean = false;


  constructor(private authService: AuthService) { }

  ngOnInit(): void {
  }

  public doesHaveError(value: string): boolean {
    if(value && value.length > 0) return true;
    return false;
  }

  async verifyLogin() {
    if(!this.loginLoading) {
      this.emailError = "";
      this.passwordError = "";
  
      if(this.email.length == 0 || this.email.length > 50) {
        this.emailError = "Please enter a valid email address";
        return;
      }
  
      if(this.password.length == 0 || this.password.length > 50) {
        this.passwordError = "Password must be between 6 and 50 characters";
        return;
      }
  
      // DO LOGIN + init error catching then dont clear to def vals
      this.loginLoading = true;
      let result = await this.authService.login(this.email, this.password);
      if(result) {
        if(result.code == "auth/wrong-password") {
          this.passwordError = "Wrong password";
        }else if(result.code == "auth/invalid-email") {
          this.emailError = "Please enter a valid email address";
        }else if(result.code == "auth/user-not-found") {
          this.emailError = "User not found. Please create an account.";
        }
      }else {
        this.email = "";
        this.password = "";
      }
      this.loginLoading = false;
    }
  }

  async googleLogin() {
    await this.authService.googleSignin();
  }

}
