import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-filter-modal',
  templateUrl: './filter-modal.component.html',
  styleUrls: ['./filter-modal.component.scss']
})
export class FilterModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<FilterModalComponent>) { }

  ngOnInit(): void {
  }

  public closeModal() {
    this.dialogRef.close();
  }

}
