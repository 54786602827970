import { Component, Inject, forwardRef, Optional, Input } from '@angular/core';
import { BaseWidget, NgAisInstantSearch, NgAisIndex } from 'angular-instantsearch';

import connectRefinementList, {
  RefinementListWidgetDescription
} from 'instantsearch.js/es/connectors/refinement-list/connectRefinementList';

@Component({
  selector: 'app-refinement-list',
  templateUrl: `./refinement-list.component.html`,
  styleUrls: ['./refinement-list.component.scss']
})
export class RefinementList extends BaseWidget{
  public state: RefinementListWidgetDescription['renderState']; // Rendering options
  @Input() refinementAttribute = '';
  constructor(
    @Inject(forwardRef(() => NgAisIndex))
    @Optional()
    public parentIndex: NgAisIndex,
    @Inject(forwardRef(() => NgAisInstantSearch))
    public instantSearchInstance: NgAisInstantSearch
  ) {
    super('RefinementList');
  }
  ngOnInit() {
    this.createWidget(connectRefinementList, {
      // instance options
      attribute: this.refinementAttribute,
    });
    super.ngOnInit();
  }
}
