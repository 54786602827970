<footer class="bg-white dark:bg-gray-800">
    <div class="max-w-screen-xl p-4 py-6 mx-auto lg:py-16 md:p-8 lg:p-10">
        <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8">
        <div class="text-center">
            <a href="/" class="flex items-center justify-center mb-5 text-2xl font-semibold text-gray-900 dark:text-white">
                <img src="../../../../assets/images/PFT_Icon.png" class="h-6 mr-3 sm:h-9" alt="Pilot Fast Track Logo" />
                Pilot Fast Track    
            </a>
            <span class="block text-sm text-center text-gray-500 dark:text-gray-400">© 2022 Pilot Fast Track. Built by <a href="https://twitter.com/AngelinaTsuboi" class="text-purple-600 hover:underline dark:text-purple-500" target="_blank">Angelina Tsuboi</a>.
            </span>
            <ul class="flex justify-center mt-5 space-x-5">
                <li>
                    <a href="https://twitter.com/PilotFastTrack" target="_blank" class="text-gray-500 hover:text-gray-900 dark:hover:text-white dark:text-gray-400 flex">
                        <span class="mr-2">Check us out on Twitter</span>
                        <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true"><path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" /></svg>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</footer>