// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyDxcdat7NtRTxSSjuMmdkw3sdcN_s5fgQ8",
    authDomain: "pilot-fast-track.firebaseapp.com",
    projectId: "pilot-fast-track",
    storageBucket: "pilot-fast-track.appspot.com",
    messagingSenderId: "688754964589",
    appId: "1:688754964589:web:faef2f6ae21594bc7be764"
  },
  algolia: {
    appId: "CLSJRGD92O",
    apiKey: "fe4c6b43ea9f958eeda98b1c89b62816"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
