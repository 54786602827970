import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { User } from 'src/app/shared/models/user.model';
import firebase from 'firebase/app';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-saved-scholarships',
  templateUrl: './saved-scholarships.component.html',
  styleUrls: ['./saved-scholarships.component.scss']
})
export class SavedScholarshipsComponent implements OnInit {

  public profileUser: User;
  public loading: boolean = false;
  public savedScholarships: any[] = [];

  constructor(public auth: AuthService, private afs: AngularFirestore, private router: Router) { }

  ngOnInit(): void {
    this.loading = true;
    this.auth.user$.subscribe((userData) => {
      if(userData) {
        console.log("user data", userData);
        this.profileUser = userData;
        if(this.profileUser.currentCertification == null) this.profileUser.currentCertification = [];
        this.getSaved();
      }
    })
    console.log(this.profileUser, "hi")
    this.loading = false;
  }

  truncateText(text: string): string {
    if(text.length > 300) {
      return text.substring(0, 300).concat('...');
    }
    return text;
  }

  public openHit(scholarshipID: string) {
    this.router.navigate([`/scholarship/${scholarshipID}`]);
  }

  getFormattedDate(input): string {
    let date = new firebase.firestore.Timestamp(input['seconds'], input['nanoseconds']).toDate();
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
  
    return month + '/' + day + '/' + year;
}

  // get first 3 saved scholarships
  private async getSaved() {
    this.savedScholarships = [];
    let scholarshipIDs = [];
    scholarshipIDs = this.profileUser.savedScholarships;
    scholarshipIDs.forEach(async(id) => {
      this.afs.collection('scholarships').doc(id).get().toPromise().then((data: any) => {
        if(data.data()) {
          this.savedScholarships.push({id: data.id, ...data.data()});
        }
      })
    })
  }

}
