<!doctype html>
<html lang="en-us">

<head>
  <link href="https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css" rel="stylesheet">
</head>

<body style="background-color: #f9fafb;">
  <app-navbar></app-navbar>
  <div class="bg-white">
    <div class="pt-6" *ngIf="scholarship">
      


      <!-- Product info -->
      <div
        class="mx-auto max-w-2xl px-4 pt-20 sm:pt-10vpb-16 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:px-8 lg:pt-16">
        <div class="lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
        <button (click)="navigate()" type="button" class="text-white bg-gray-400 hover:bg-gray-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
          <svg aria-hidden="true" class="mr-2 -ml-1 w-5 h-5" fill="currentColor" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"></path></svg>
          Back
        </button>
          <h1 class="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mt-10">{{scholarship.title}}</h1>
        </div>

        <!-- Options -->
        <div class="mt-4 lg:row-span-3 lg:mt-0">
          <!-- TODO: change this to include flight hour compensation -->
          <h2 class="mb-1">Amount</h2>
          <p class="text-3xl tracking-tight text-gray-900" *ngIf="scholarship.money">${{scholarship.money}}</p>
          <p class="text-3xl tracking-tight text-gray-900" *ngIf="scholarship.flightHours">{{scholarship.flightHours}} hours</p>

          <h3 class="text-sm font-medium text-gray-900 mt-10">Funded by</h3>
          <p class="text-xl tracking-tight text-gray-900"><span
              *ngIf="!scholarship.issuerWebsite || scholarship.issuerWebsite.length == 0">{{scholarship.issuer}}</span>
            <a class="issuerLink" (click)="visitWebsite(scholarship.issuerWebsite)"
              *ngIf="scholarship.issuerWebsite && scholarship.issuerWebsite.length > 0"><span>{{scholarship.issuer}}</span></a>
          </p>

          <h3 class="text-sm font-medium text-gray-900 mt-10">Deadline</h3>
          <p class="text-xl tracking-tight text-gray-900">{{getFormattedDate(scholarship.deadline)}}</p>

          <h3 class="text-sm font-medium text-gray-900 mt-10">Education Level</h3>
          <p class="text-xl tracking-tight text-gray-900">{{scholarship.education}}</p>

          <h3 class="text-sm font-medium text-gray-900 mt-10">Location</h3>
          <p class="text-xl tracking-tight text-gray-900" *ngIf="scholarship.location">{{getScholarshipLocation(scholarship.location)}}</p>
          <p class="text-xl tracking-tight text-gray-900" *ngIf="!scholarship.location">Anywhere</p>

          <h3 class="text-sm font-medium text-gray-900 mt-10">Aircraft</h3>
          <p class="text-xl tracking-tight text-gray-900">{{scholarship.aircraft}}</p>
          
          <h3 class="text-sm font-medium text-gray-900 mt-10">Career Path</h3>
          <p class="text-xl tracking-tight text-gray-900">{{scholarship.career}}</p>

          <div *ngIf="scholarship.rating && (scholarship.rating != 'Any')">
            <h3 class="text-sm font-medium text-gray-900 mt-10">Rating</h3>
            <p class="text-xl tracking-tight text-gray-900">{{scholarship.rating}}</p>
          </div>

          <div *ngIf="scholarship.gender != 'Both'">
            <h3 class="text-sm font-medium text-gray-900 mt-10">Gender Specific</h3>
            <p class="text-xl tracking-tight text-gray-900">{{scholarship.gender}} Scholarship</p>
          </div>

          <h3 class="text-sm font-medium text-gray-900 mt-10">Age Range</h3>
          <p class="text-xl tracking-tight text-gray-900">{{scholarship.minAge}} - {{scholarship.maxAge}} yrs</p>

          <div *ngIf="scholarship.specialFilter && (scholarship.specialFilter != 'NA')">
            <h3 class="text-sm font-medium text-gray-900 mt-10">Additional Info</h3>
            <p class="text-xl tracking-tight text-gray-900">{{scholarship.specialFilter}} Scholarship</p>
          </div>


          <form class="mt-10">
            <button type="button" (click)="visitWebsite(scholarship.url)"
              class="mt-10 flex w-full items-center justify-center rounded-md border border-transparent bg-blue-600 py-3 px-8 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">Visit
              Scholarship</button>

            <button type="button" (click)="saveScholarship()"
              class="mt-5 flex w-full items-center justify-center py-3 px-8 text-blue-700 hover:text-white border border-blue-700 text-base hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-sm px-5 py-2.5 text-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-600 dark:focus:ring-blue-800"
              *ngIf="profileUser">

              <span *ngIf="!savedLoading && !isSaved()">Save Scholarship</span>
              <span *ngIf="!savedLoading && isSaved()">
                Saved
              </span>
              <span *ngIf="savedLoading">
                <svg role="status" class="inline mr-3 w-4 h-4 text-white animate-spin" viewBox="0 0 100 101" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="#E5E7EB" />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentColor" />
                </svg>
                Saving...
              </span>

            </button>
          </form>
        </div>

        <div class="py-10 lg:col-span-2 lg:col-start-1 lg:border-r lg:border-gray-200 lg:pt-6 lg:pb-16 lg:pr-8 pb-1">
          <!-- Description and details -->
          <div>
            <h3 class="sr-only">Description</h3>

            <!-- <div class="space-y-6">
              <p class="text-base text-gray-900">{{scholarship.description}}</p>
            </div> -->

            <!-- <div class="aspect-w-3 aspect-h-2 overflow-hidden rounded-lg">
              <img [src]="scholarship.mainImage" alt="Model wearing plain black basic tee."
                class="h-full w-full object-cover object-center">
            </div> -->
          </div>

          <div>
            <div class="mt-4 space-y-6">
              <p class="text-sm text-gray-600">{{scholarship.description}}</p>
            </div>
          </div>

          <div class="mt-10">
            <h3 class="text-sm font-medium text-gray-900">Eligibility Requirements</h3>

            <div class="mt-4">
              <ul role="list" class="list-disc space-y-2 pl-4 text-sm">
                <li class="text-gray-400"><span class="text-gray-600">Certification Requirement:
                    {{scholarship.requiredCertification}}</span></li>

                    <li class="text-gray-400"><span class="text-gray-600">Education Level:
                      {{scholarship.education}}</span></li>

                      <li class="text-gray-400"><span class="text-gray-600">Career Path:
                        {{scholarship.career}}</span></li>

                        <li class="text-gray-400" *ngIf="scholarship.rating"><span class="text-gray-600">Rating:
                          {{scholarship.rating}}</span></li>

                        <li class="text-gray-400" *ngIf="scholarship.location"><span class="text-gray-600">Location:
                          {{getScholarshipLocation(scholarship.location)}}</span></li>
                          <li class="text-gray-400" *ngIf="!scholarship.location"><span class="text-gray-600">Location:
                            Anywhere</span></li>

                        <li class="text-gray-400"><span class="text-gray-600">Aircraft:
                          {{scholarship.aircraft}}</span></li>

                <li class="text-gray-400"><span class="text-gray-600">Age Range: {{scholarship.minAge}} -
                    {{scholarship.maxAge}} yrs</span></li>

                <li class="text-gray-400" *ngIf="scholarship.gender != 'Both'"><span class="text-gray-600">Gender
                    Specific Scholarship: {{scholarship.gender}} Scholarship</span></li>

                    <li class="text-gray-400" *ngIf="scholarship.specialFilter && scholarship.specialFilter != 'NA'"><span class="text-gray-600">Additional Info: {{scholarship.specialFilter}} Scholarship</span></li>

                <!-- TODO: add education requirement -->
              </ul>
            </div>
          </div>

          
        </div>
      </div>
      <app-footer></app-footer>
    </div>

  </div>

</body>

</html>