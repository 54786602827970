<!doctype html>
<html lang="en-us">

<head>
    <link href="https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css" rel="stylesheet">
</head>

<body style="background-color: #f9fafb; min-height: 100vh; height: 100%;">

    <app-navbar></app-navbar>


    <div class="">

        <div class="p-5 sm:p-20 pt-30">
            <ol class="inline-flex items-center space-x-1 md:space-x-3">
                <li>
                    <div class="flex items-center">
                        <a href="/profile"
                            class="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">Profile</a>
                    </div>
                </li>
                <li aria-current="page">
                    <div class="flex items-center">
                        <svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clip-rule="evenodd"></path>
                        </svg>
                        <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">Edit
                            Profile</span>
                    </div>
                </li>
            </ol>
            <div class="mt-6 md:mt-5 md:col-span-2">
                <form action="#" *ngIf="profileUser">
                    <div class="shadow overflow-hidden sm:rounded-md">
                        <div class="px-4 py-4 bg-white sm:p-6">
                            <h6 class="mb-3 font-bold">Profile Information</h6>
                            <div class="grid grid-cols-6 gap-6">
                               
                                <div class="col-span-6 sm:col-span-4">
                                    <label for="first_name" class="block text-sm font-medium text-gray-700">Full
                                        name</label>
                                    <input type="text" class="form-control" [(ngModel)]="profileUser.name"
                                        name="first_name" id="first_name" autocomplete="given-name"
                                        class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                                    <div class="invalid" *ngIf="doesHaveError(fullNameError)">
                                        {{fullNameError}}
                                    </div>
                                </div>

                                <div class="col-span-6 sm:col-span-4">
                                    <label for="email_address" class="block text-sm font-medium text-gray-700">Email
                                        address</label>
                                    <input type="text" [(ngModel)]="profileUser.email" name="email_address"
                                        id="email_address" autocomplete="email" disabled
                                        class="disabled mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                                </div>

                                <div class="col-span-6 sm:col-span-4">
                                    <label for="country"
                                        class="block text-sm font-medium text-gray-700">Certification</label>
                                    <mat-form-field class="select-list" appearance="outline">

                                        <mat-chip-list #chipList style="background-color: white;">
                                            <mat-chip *ngFor="let cert of certifications" [selectable]="selectable"
                                                [removable]="removable" (removed)="remove(cert, 'certifications')">
                                                {{cert}}
                                                <mat-icon matChipRemove (click)="remove(cert, 'certifications')">close</mat-icon>
                                            </mat-chip>
                                            <input placeholder="Enter certification..." #certInput
                                                [formControl]="certCtrl" [matAutocomplete]="auto"
                                                [matChipInputFor]="chipList"
                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                (matChipInputTokenEnd)="add($event, 'certifications')">
                                        </mat-chip-list>
                                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event, 'certifications')">
                                            <div *ngFor="let cert of filteredCerts | async">
                                                <mat-option [value]="cert" *ngIf="!listContains(cert, 'certifications')">
                                                    {{cert}}
                                                </mat-option>
                                            </div>

                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>

                                <div class="col-span-6 sm:col-span-3">
                                    <label for="gender" class="block text-sm font-medium text-gray-700">Gender</label>
                                    <select id="gender" name="gender" autocomplete="gender"
                                        [(ngModel)]="profileUser.gender"
                                        class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                        <option value="">Not Selected</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                </div>

                                <div class="col-span-3">
                                    <label for="age" class="block text-sm font-medium text-gray-700">Age</label>
                                    <input type="number" name="age" id="age" placeholder="Enter Age..."
                                        [(ngModel)]="profileUser.age"
                                        class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                                    <div class="invalid" *ngIf="doesHaveError(ageError)">
                                        {{ageError}}
                                    </div>
                                </div>

                                <div class="col-span-6 sm:col-span-3">
                                    <label for="aircraft"
                                        class="block text-sm font-medium text-gray-700">Aircraft</label>
                                    <select id="aircraft" name="aircraft" autocomplete="aircraft"
                                        [(ngModel)]="profileUser.aircraft"
                                        class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                        <option *ngFor="let aircraft of aircraftData" [value]="aircraft">{{aircraft}}
                                        </option>
                                    </select>
                                </div>

                                <div class="col-span-6 sm:col-span-3">
                                    <label for="career" class="block text-sm font-medium text-gray-700">Career
                                        Track</label>
                                    <select id="career" name="career" autocomplete="career"
                                        [(ngModel)]="profileUser.career"
                                        class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                        <option value="">Not Selected</option>
                                        <option *ngFor="let career of careerData" [value]="career">{{career}}</option>
                                    </select>
                                </div>

                                <div class="col-span-6 sm:col-span-3">
                                    <label for="goalCertification" class="block text-sm font-medium text-gray-700">Goal
                                        Certification</label>
                                    <select id="goalCertification" name="goalCertification"
                                        autocomplete="goalCertification" [(ngModel)]="profileUser.goalCertification"
                                        class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                        <option value="">Not Selected</option>
                                        <option *ngFor="let cert of certificationsData" [value]="cert">{{cert}}</option>
                                    </select>
                                </div>

                                <div class="col-span-6 sm:col-span-3">
                                    <label for="goalRating"
                                        class="block text-sm font-medium text-gray-700">Goal Rating</label>
                                    <select id="goalRating" name="goalRating" autocomplete="goalRating"
                                        [(ngModel)]="profileUser.goalRating"
                                        class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                        <option value="">Not Selected</option>
                                        <option *ngFor="let rating of ratingsData" [value]="rating">{{rating}}
                                        </option>
                                    </select>
                                </div>

                                <div class="col-span-6 sm:col-span-3">
                                    <label for="special"
                                        class="block text-sm font-medium text-gray-700">Additional Filter</label>
                                    <label for="special"
                                        class="block text-sm text-gray-500 mb-2">We only use this data to customize your scholarship search</label>  
                                    <!-- <select id="special" name="special" autocomplete="special"
                                        [(ngModel)]="profileUser.specialFilter"
                                        class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                        <option value="">Not Selected</option>
                                        <option *ngFor="let special of specialFilters" [value]="special">{{special}}
                                        </option>
                                    </select> -->
                                    <mat-form-field class="select-list" appearance="outline">

                                        <mat-chip-list #filterChipList style="background-color: white;">
                                            <mat-chip *ngFor="let filter of selectedAdditonalFilters" [selectable]="selectable"
                                                [removable]="removable" (removed)="remove(filter, 'filters')">
                                                {{filter}}
                                                <mat-icon matChipRemove (click)="remove(filter, 'filters')">close</mat-icon>
                                            </mat-chip>
                                            <input placeholder="Select filter..." #filterInput
                                                [formControl]="filterCtrl" [matAutocomplete]="autoFilter"
                                                [matChipInputFor]="filterChipList"
                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                (matChipInputTokenEnd)="add($event, 'filters')">
                                        </mat-chip-list>
                                        <mat-autocomplete #autoFilter="matAutocomplete" (optionSelected)="selected($event, 'filters')">
                                            <div *ngFor="let filter of filteredAdditionalFilters | async">
                                                <mat-option [value]="filter" *ngIf="!listContains(filter, 'filters')">
                                                    {{filter}}
                                                </mat-option>
                                            </div>

                                        </mat-autocomplete>
                                    </mat-form-field>
                                </div>

                                <div class="col-span-6 sm:col-span-4">
                                    <label class="block text-sm font-medium text-gray-700">
                                        Photo
                                    </label>
                                    <div class="mt-1 flex items-center">
                                        <span class="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                                            <img [src]="profileUser.photoURL" class="h-full w-full text-gray-300" />
                                            <input type="file" #file accept="image/jpeg,image/x-png" id="customFile" name="file" style="display: none" (change)="handleFileInput($event.target.files)">
                                        </span>
                                        <button type="button" (click)="file.click()"
                                            class="profile-pic-button ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                            <span *ngIf="!profileLoading">Change</span> <mat-spinner [diameter]="20" *ngIf="profileLoading"></mat-spinner>
                                        </button>
                                    </div>
                                </div>


                            </div>
                        </div>
                        <div class="px-0 sm:px-4 py-3 bg-gray-50 text-right sm:px-6">
                            <button
                                class="inline-flex mr-5 justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                (click)="deleteAccount()">
                                Delete Profile
                            </button>
                            <button
                                class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                (click)="verifyUpdate()" [disabled]="updateLoading">
                                <span *ngIf="updateLoading">
                                    <svg role="status" class="inline mr-3 w-4 h-4 text-white animate-spin"
                                        viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                            fill="#E5E7EB" />
                                        <path
                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                            fill="currentColor" />
                                    </svg>
                                    Loading...
                                </span>


                                <span *ngIf="!updateLoading">Update profile</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

</body>

</html>