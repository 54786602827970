import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { User } from 'src/app/shared/models/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { DeleteAccountModalComponent } from '../../parts/delete-account-modal/delete-account-modal.component';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {FormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {Observable} from 'rxjs';
import {finalize, map, startWith} from 'rxjs/operators';
import { certification } from 'src/app/shared/data/certifications.data';
import { aircraftData } from 'src/app/shared/data/aircraft.data';
import { careerData } from 'src/app/shared/data/career.data';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import { FileUpload } from 'src/app/shared/models/fileupload.model';
import { specialFiltersData } from 'src/app/shared/data/specialFilters.data';
import { ratingsData } from 'src/app/shared/data/ratings.data';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {

  public loading: boolean = false;
  public profileUser: User;
  public aircraftData: string[] = aircraftData; 
  public ratingsData: string[] = ratingsData; 
  public specialFilters: string[] = specialFiltersData; 
  public certificationsData: string[] = certification;
  public careerData: string[] = careerData;
  public updateLoading: boolean = false;
  public profileLoading: boolean = false;
  public fileToUpload: File | null = null;

  public fullNameError: string = "";
  public ageError: string = "";

  separatorKeysCodes: number[] = [ENTER, COMMA];
  certCtrl = new FormControl('');
  filteredCerts: Observable<string[]>;
  certifications: string[] = [];
  allCertifications: string[] = certification;

  filterCtrl = new FormControl('');
  filteredAdditionalFilters: Observable<string[]>;
  selectedAdditonalFilters: string[] = [];
  allFilterAdditonals: string[] = this.specialFilters;

  @ViewChild('certInput') certInput: ElementRef<HTMLInputElement>;
  @ViewChild('filterInput') filterInput: ElementRef<HTMLInputElement>;

  constructor(public dialog: MatDialog, public auth: AuthService,  private afs: AngularFirestore, private _snackBar: MatSnackBar, private storage: AngularFireStorage) {
    this.filteredCerts = this.certCtrl.valueChanges.pipe(
      startWith(null),
      map((cert: string | null) => (cert ? this._filter(cert, 'certifications') : this.allCertifications.slice())),
    );
    this.filteredAdditionalFilters = this.filterCtrl.valueChanges.pipe(
      startWith(null),
      map((filter: string | null) => (filter ? this._filter(filter, 'filters') : this.allFilterAdditonals.slice())),
    );
   }

  ngOnInit(): void {
    this.loading = true;
    this.auth.user$.subscribe((userData) => {
      if(userData) {
        console.log("user data", userData);
        this.profileUser = userData;
        if(!this.profileUser.gender) {
          this.profileUser.gender = "";
        }
        if(!this.profileUser.career) {
          this.profileUser.career = "";
        }
        
        this.certifications = userData.currentCertification;
        if(!this.certifications) this.certifications = [];

        if(!this.profileUser.goalCertification) {
          this.profileUser.goalCertification = "";
        }

        this.selectedAdditonalFilters = userData.specialFilter;
        if(!this.selectedAdditonalFilters) {
          this.selectedAdditonalFilters = [];
        }

        if(!this.profileUser.goalRating) {
          this.profileUser.goalRating = "";
        }
      }
    })
    console.log(this.profileUser, "hi")
    this.loading = false;
  }

  deleteAccount(){
    const dialogRef = this.dialog.open(DeleteAccountModalComponent, {
      width: '500px',
      hasBackdrop: false,
      backdropClass: 'custom-class',
      panelClass: 'custom-class',
      data: {
        profileUID: this.profileUser.uid
      }
    });
  }

  listContains(item: string, category: string) {
    if(category == "certifications") {
      return this.certifications.includes(item)
    }else if(category == "filters") {
      return this.selectedAdditonalFilters.includes(item)
    }
    return false;
  }

  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  // Multi-Select Methods
  add(event: MatChipInputEvent, category: string): void {
    const value = (event.value || '').trim();

    // Add our cert
    if (value && category == "certifications") {
      this.certifications.push(value);
    }

    // Clear the input value
    if (event.input) {
      event.input.value = '';
     }    

    if(category == "certifications") {
      this.certCtrl.setValue(null);
      this.profileUser.currentCertification = this.certifications;
    }

    if(category == "filters") {
      this.filterCtrl.setValue(null);
      this.profileUser.specialFilter = this.selectedAdditonalFilters;
    }
   
  }

  remove(cert: string, category: string): void {
    if(category == "certifications") {
      const index = this.certifications.indexOf(cert);

      if (index >= 0) {
        this.certifications.splice(index, 1);
      }
      this.profileUser.currentCertification = this.certifications;
    }

    if(category == "filters") {
      const index = this.selectedAdditonalFilters.indexOf(cert);

      if (index >= 0) {
        this.selectedAdditonalFilters.splice(index, 1);
      }
      this.profileUser.specialFilter = this.selectedAdditonalFilters;
    }
    
  }

  selected(event: MatAutocompleteSelectedEvent, category: string): void {
    if(category == "certifications") {
      this.certifications.push(event.option.viewValue);
      this.certInput.nativeElement.value = '';
      this.certCtrl.setValue(null);
    }

    if(category == "filters") {
      this.selectedAdditonalFilters.push(event.option.viewValue);
      this.filterInput.nativeElement.value = '';
      this.filterCtrl.setValue(null);
    }
    
  }

  public doesHaveError(value: string): boolean {
    if(value && value.length > 0) return true;
    return false;
  }

  private _filter(value: string, category: string): string[] {
    const filterValue = value.toLowerCase();
    if(category == "certifications") {
      return this.allCertifications.filter(cert => cert.toLowerCase().includes(filterValue));
    }else {
      return this.allFilterAdditonals.filter(filter => filter.toLowerCase().includes(filterValue));
    }
  }

  public async verifyUpdate() {
    console.log(this.profileUser)
    this.profileUser.currentCertification = this.certifications;
    this.profileUser.specialFilter = this.selectedAdditonalFilters;
    this.ageError = "";
    this.fullNameError = "";
    if(this.profileUser.name.length == 0 || this.profileUser.name.length > 30) {
      this.fullNameError = "Name must be filled out and be between 4 and 30 characters";
      return;
    }

    if(this.profileUser.age < 13 || this.profileUser.age > 100) {
      this.ageError = "Please enter a valid age";
      return;
    }
    this.updateLoading = true;
    await this.afs.collection("users").doc(this.profileUser.uid).update({
      ...this.profileUser
    })
    this.updateLoading = false;
    this._snackBar.open('Account Updated!', 'OK', {
      duration: 3000
    });
  }

  uploadTask(file: File){
    let task: AngularFireUploadTask;
    let downloadURL;
    const basePath = '/uploads';
    this.profileLoading = true;

     // The storage path
    const path = `${basePath}/${Math.random().toString(36).substring(2, 9)}_${file.name}`;
     // Reference to storage bucket
    const ref = this.storage.ref(path);

     // The main task
    task = this.storage.upload(path, file);

    // TODO: delete prev user profile

    task.snapshotChanges().pipe(
       // The file's download URL
       finalize(async () =>  {
         downloadURL = await ref.getDownloadURL().toPromise();
         this.profileUser.photoURL = downloadURL;
         this.profileLoading = false;
       })
     ).subscribe();

  }

  // Upload User Image
  async handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    this.uploadTask(this.fileToUpload);
  }

  

}
