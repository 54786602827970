import { Options } from '@angular-slider/ngx-slider';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { aircraftData } from 'src/app/shared/data/aircraft.data';
import { careerData } from 'src/app/shared/data/career.data';
import { certification } from 'src/app/shared/data/certifications.data';
import { ratingsData } from 'src/app/shared/data/ratings.data';
import { specialFiltersData } from 'src/app/shared/data/specialFilters.data';
import { Scholarship } from 'src/app/shared/models/scholarship.model';
import { ScholarshipService } from 'src/app/shared/services/scholarship.service';

@Component({
  selector: 'app-submit-scholarships',
  templateUrl: './submit-scholarships.component.html',
  styleUrls: ['./submit-scholarships.component.scss']
})
export class SubmitScholarshipsComponent implements OnInit {
  minDate: Date = new Date();
  maxDate: Date = new Date(new Date().setFullYear(new Date().getFullYear() + 10));
  certifications: string[] = certification;
  complete: boolean = false;
  uploadLoading: boolean = false;
  imageError: string = "";
  aircrafts: string[] = [];
  specialFilters: string[] = [];
  ratings: string[] = [];
  careers: string[] = [];
  loading: boolean = false;
  firstFormGroup = this._formBuilder.group({
    scholarshipName: ['', [Validators.required, Validators.maxLength(50)]],
    issuerName: ['', [Validators.required, Validators.maxLength(30)]],
    money: [100, [Validators.required, Validators.min(0), Validators.max(1000000)]],
    flightHours: [10, [Validators.required, Validators.min(1), Validators.max(5000)]],
    isFlightHourCompensation: [false],
    issuerWebsite: ['', [Validators.min(0), Validators.maxLength(200)]],
    scholarshipDeadline: [null, [Validators.required]],
    scholarshipWebsite: ['', [Validators.required, Validators.maxLength(200)]],
    scholarshipDetails: ['', [Validators.required, Validators.maxLength(10000)]]
  });
  secondFormGroup = this._formBuilder.group({
    certificationRequirement: ['None', Validators.required],
    gender: ['Both', Validators.required],
    career: ['Any', Validators.required],
    aircraft: ['Any', Validators.required],
    education: ['Any', Validators.required],
    minAge: [13, [Validators.required, Validators.min(12), Validators.max(100)]],
    maxAge: [40, [Validators.required, Validators.min(12), Validators.max(100)]],
    location: ['', [Validators.min(5), Validators.max(100)]],
    rating: ['Any', [Validators.required]],
    specialFilter: ['NA', [Validators.required]]
  });
  isLinear = false;


  constructor(private _formBuilder: FormBuilder, private scholarshipService: ScholarshipService) {
    this.aircrafts = aircraftData;
    this.specialFilters = specialFiltersData;
    this.ratings = ratingsData;
    this.careers = careerData;
  }

  ngOnInit(): void {
    this.complete = false;
    this.loading = false;
    this.uploadLoading = false;
  }

  public onToggleFlightHours(value:boolean) {
    this.firstFormGroup.controls['isFlightHourCompensation'].setValue(value);
  }

  public doesHaveError(value: string): boolean {
    if (value && value.length > 0) return true;
    return false;
  }

  isHovering: boolean;

  files: File[] = [];

  toggleHover(event: boolean) {
    this.isHovering = event;
  }

  onDrop(files: FileList) {
    this.files = []
    this.uploadLoading = true;
    console.log("file is ", files[0])
    this.files.push(files[0]);
  }


  public checkSubmit() {
    this.createScholarship();
  }

  public isFlightHourCompensation():boolean {
    return this.firstFormGroup.get('isFlightHourCompensation').value;
  }

  restart(){    
    this.firstFormGroup.reset();
    this.secondFormGroup.reset();
    this.complete = false;
  }

  private async createScholarship() {
    this.loading = true;
    let scholarship = {
      title: this.firstFormGroup.controls['scholarshipName'].value,
      description: this.firstFormGroup.controls['scholarshipDetails'].value,
      deadline: this.firstFormGroup.controls['scholarshipDeadline'].value,
      minAge: this.secondFormGroup.controls['minAge'].value,
      maxAge: this.secondFormGroup.controls['maxAge'].value,
      requiredCertification: this.secondFormGroup.controls['certificationRequirement'].value,
      url: this.firstFormGroup.controls['scholarshipWebsite'].value,
      createdAt: new Date(),
      money: 0,
      flightHours: 0,
      gender: this.secondFormGroup.controls['gender'].value,
      issuer: this.firstFormGroup.controls['issuerName'].value,
      issuerWebsite: this.firstFormGroup.controls['issuerWebsite'].value,
      career: this.secondFormGroup.controls['career'].value,
      aircraft: this.secondFormGroup.controls['aircraft'].value,
      education: this.secondFormGroup.controls['education'].value,
      location: this.secondFormGroup.controls['location'].value,
      specialFilter: this.secondFormGroup.controls['specialFilter'].value,
      rating: this.secondFormGroup.controls['rating'].value
    }

    if(this.isFlightHourCompensation()) {
      scholarship.flightHours = parseInt(this.firstFormGroup.controls['flightHours'].value)
    }else {
      scholarship.money = parseInt(this.firstFormGroup.controls['money'].value)
    }
    await this.scholarshipService.createScholarship(scholarship);
    this.loading = false;
    this.complete = true;
  }

}
