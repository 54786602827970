import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Scholarship } from '../models/scholarship.model';

@Injectable({
  providedIn: 'root'
})
export class ScholarshipService {

  constructor(private afs: AngularFirestore) { }

  async createScholarship(scholarship: any): Promise<any> {
    let promise = this.afs.firestore.collection("scholarships").add(scholarship);
    return promise;
  }
}
