import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { HomeComponent } from './components/pages/home/home.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ScholarshipsComponent } from './components/pages/scholarships/scholarships.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { SubmitScholarshipsComponent } from './components/pages/submit-scholarships/submit-scholarships.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatStepperModule} from '@angular/material/stepper';
import {MatButtonModule} from '@angular/material/button';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatInputModule} from '@angular/material/input';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatMenuModule} from '@angular/material/menu';
import {MatNativeDateModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NavbarComponent } from './components/parts/navbar/navbar.component';
import { MatIconModule } from '@angular/material/icon';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatChipsModule} from '@angular/material/chips';
import { ProfileComponent } from './components/pages/profile/profile.component';
import { EditProfileComponent } from './components/pages/edit-profile/edit-profile.component';
import { DeleteAccountModalComponent } from './components/parts/delete-account-modal/delete-account-modal.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FooterComponent } from './components/parts/footer/footer.component';
import { DropzoneDirective } from './shared/directives/dropzone.directive';
import { UploadTaskComponent } from './components/parts/upload-task/upload-task.component';
import { NgAisModule, NgAisPaginationModule } from 'angular-instantsearch';
import { ScholarshipViewComponent } from './components/pages/scholarship-view/scholarship-view.component';
import { SavedScholarshipsComponent } from './components/pages/saved-scholarships/saved-scholarships.component';
import { CreateAccountComponent } from './components/pages/create-account/create-account.component';
import { LoginComponent } from './components/pages/login/login.component';
import { NoResults } from './components/parts/no-results/no-results.component';
import { RefinementList } from './components/parts/refinement-list/refinement-list.component';
import { FilterModalComponent } from './components/parts/filter-modal/filter-modal.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    ScholarshipsComponent,
    ContactComponent,
    SubmitScholarshipsComponent,
    NavbarComponent,
    ProfileComponent,
    EditProfileComponent,
    DeleteAccountModalComponent,    
    FooterComponent,
    DropzoneDirective,
    UploadTaskComponent,
    ScholarshipViewComponent,
    SavedScholarshipsComponent,
    CreateAccountComponent,
    LoginComponent,
    NoResults,
    RefinementList,
    FilterModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatStepperModule,
    MatButtonModule,
    MatInputModule,
    MatProgressSpinnerModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSliderModule,
    MatSelectModule,
    MatDialogModule,
    MatIconModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFireStorageModule,
    AngularFirestoreModule,
    AutocompleteLibModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatIconModule,
    MatSnackBarModule,
    MatButtonModule,
    MatDatepickerModule,  
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    NgAisModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
