import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-delete-account-modal',
  templateUrl: './delete-account-modal.component.html',
  styleUrls: ['./delete-account-modal.component.scss']
})
export class DeleteAccountModalComponent implements OnInit {
  profileUID: string = ""; 
  deleteLoading: boolean = false;

  constructor(public dialogRef: MatDialogRef<DeleteAccountModalComponent>, private auth: AuthService, private afs: AngularFirestore, private afAuth: AngularFireAuth, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.profileUID = this.data.profileUID;
  }

  public closeModal() {
    this.dialogRef.close();
  }

  public async deleteAccount() {
    this.deleteLoading = true;
    if(this.profileUID.length > 0) {
      this.afs.collection("users").doc(this.profileUID).delete().then(async() => {
        await this.afAuth.currentUser.then(async(user) => {
          await user.delete();
          this.auth.signOut();
          this.closeModal();
        });
        
        this.deleteLoading = false;
      })
    }else {
      alert("Please try again by refreshing the page.")
      this.deleteLoading = false;
    }
  }


}
