import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import firebase from 'firebase/app';
import { User } from 'src/app/shared/models/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-scholarship-view',
  templateUrl: './scholarship-view.component.html',
  styleUrls: ['./scholarship-view.component.scss']
})
export class ScholarshipViewComponent implements OnInit {

  constructor(private route: ActivatedRoute, private afs: AngularFirestore, private auth: AuthService, public router: Router) { }

  scholarship: any = null;
  loading: boolean = false;
  profileUser: User;
  savedLoading: boolean = false;
  savedScholarships = [];
  scholarshipId: string = "";

  public navigate() {
    this.router.navigate(['/scholarships']);
  }


  async ngOnInit() {
    this.loading = true;
    this.auth.user$.subscribe((userData) => {
      if (userData) {
        console.log("user data", userData);
        this.profileUser = userData;
        this.savedScholarships = userData.savedScholarships;
      }
    })

    this.route.paramMap.subscribe(paramMap => {
      // TODO: check for invalid uid
      let uid = paramMap.get('scholarshipID');
      this.scholarshipId = uid;
      this.afs.collection(`scholarships`).doc(uid).get().subscribe((data: any) => {
        if (data) {
          this.scholarship = { id: data.id, ...data.data() };
          console.log('scholarship', this.scholarship);
        }
        this.loading = false;
      })
    })
  }

  getFormattedDate(input): string {
    input = this.scholarship.deadline;
    let date = new firebase.firestore.Timestamp(input['seconds'], input['nanoseconds']).toDate();
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');

    return month + '/' + day + '/' + year;
  }

  getScholarshipLocation(location: string) {
    if(location.length == 0) return "Anywhere";
    return location;
  }

  async saveScholarship() {
    this.savedLoading = true;
    if (this.savedScholarships.includes(this.scholarshipId)) {
      let resultant = this.savedScholarships;
      resultant.splice(resultant.indexOf(this.scholarshipId), 1);
      this.savedScholarships = resultant;
    } else {
      this.savedScholarships.push(this.scholarshipId);
    }
    await this.afs.collection('users').doc(this.profileUser.uid).update({ savedScholarships: this.savedScholarships });
    this.savedLoading = false;
  }

  isSaved(): boolean {
    return this.savedScholarships.includes(this.scholarshipId);
  }

  visitWebsite(url: string) {
    window.open(url, "_blank");
  }

}
