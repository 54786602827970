<!doctype html>
<html lang="en-us">

<body>

    <app-navbar></app-navbar>

    <section class="bg-gray-50 dark:bg-gray-900 sm:pt-10 pt-20">
        <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">

            <div
                class="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
                    <h1
                        class="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                        Login to account
                    </h1>
                    <div class="space-y-4 md:space-y-6" action="#">
                        <div>
                            <label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your
                                email</label>
                            <input type="email" name="email" id="email"
                                [(ngModel)]="email"
                                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="name@company.com" required="">
                                <div class="invalid" *ngIf="doesHaveError(emailError)">
                                    {{emailError}}
                                </div>
                        </div>
                        <div>
                            <label for="password"
                                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                            <input [(ngModel)]="password" type="password" name="password" id="password" placeholder="••••••••"
                                class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                required="">
                                <div class="invalid" *ngIf="doesHaveError(passwordError)">
                                    {{passwordError}}
                                </div>
                        </div>

                        <button
                            (click)="verifyLogin()"
                            class="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            
                            <mat-spinner [diameter]="20" *ngIf="loginLoading"></mat-spinner><span *ngIf="!loginLoading">Login to account</span>
                        </button>
                        <div class="text-center text-gray-500 text-xs">
                            OR
                        </div>

                        <div>
                            <button
                               (click)="googleLogin()"
                                class="border h-10 inline-flex items-center justify-center rounded-sm shadow-md text-gray-700 w-full">
                                <img src="https://aid-frontend.prod.atl-paas.net/atlassian-id/front-end/3.4.2/static/media/google-logo.c21ca9d1.svg"
                                    class="mr-3 w-4" />
                                <span class="font-semibold text-sm">
                                    Continue with Google
                                </span>
                            </button>
                        </div>
                        <p class="text-sm font-light text-gray-500 dark:text-gray-400">
                            Don't have an account? <a href="/create-account"
                                class="font-medium text-primary-600 hover:underline dark:text-primary-500">Create an Account</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <app-footer></app-footer>
</body>